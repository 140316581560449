@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap'); */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'DM Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* * {
  font-family: 'DM Sans', sans-serif !important;
} */

/* div{
  background-color: #f3dbbe;
} */

.font-inter {
  font-family: Inter !important;
}
.font-sans {
  font-family: 'DM Sans' !important;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* border-radius: 10px; */
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
}
 
::-webkit-scrollbar-thumb {
  background-color: #03565A;
  /* outline: 1px solid white; */
  /* border-radius: 10px; */
}

.react-date-picker.react-date-picker--closed.react-date-picker--enabled{
  /* width: 100% !important; */
  background: #fff;
}

.disabled-table{
  background-color: rgba(236, 240, 241, 0.5);
 pointer-events: none;
}

/* Updated checkbox default color */
input[type='checkbox'] {
  accent-color: #03565A !important;
  cursor: pointer;
}
/* Custom CSS */
.cursor-pointer{
  cursor: pointer !important;
}
.primary-border{
  border: 1px solid #03565A !important;
}
.custom-border{
  border: 2px solid #03565A !important;
}
.custom-border-gray{
  border: 1px solid #D6D6D6;
}
.custom-common-theme-text-color{
  color: #03565A !important;
}
.custom-common-theme-bg-color{
  background-color: #03565A !important;
}
.bg-secondary-400{
  background-color: #A2C0C2 !important;
}
.custom-common-secondary-bg{
  background-color: #E5EEEE !important;
}
.bg-secondary-100{
  background-color: #E9E9E9 !important;
}
.bg-warning-100 {
  background-color: #f8fac9 !important;
}
.custom-bg-warning {
  background-color: #f2e2b5;
}
.custom-bg-warning-200 {
  background-color: #F0DBC8 !important;
}
.bg-warning-400{
  background-color: #FDC27B !important;
}
.custom-bg-blue-200 {
  background-color: #F1F5F9;
}
.bg-light-blue {
  background: #D1E0E0;
}
.custom-bg-white-150 {
  background-color: #F8F8F8;
}
.custom-bg-white-200 {
  background-color: #F8FAFB;
}
.no-shadow {
  box-shadow: none !important;
}
.custom-bg-gray-200 {
  background-color: #e0e0e0;
}
.custom-bg-gray-300 {
  background-color: #F1F2F5;
}
.custom-bg-gray-100 {
  background-color: #f5f5f5;
}

.custom-bg-accordion {
  background-color: #D6D6D6  ;
}

.bg-color-inherit {
  background-color: inherit;
}
.custom-font-color{
  color: #717171  ;
}

.custom-text-color{
  color: #D6D6D6;
}

.custom-text-black{
 color: #31354A;
}


.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-24 {
  font-size: 24px !important;
}

.custom-font-500{
  font-weight: 500 !important;
}
.custom-font-600{
  font-weight: 600 !important;
}
.custom-font-700{
  font-weight: 700 !important;
}

.radius-6px {
  border-radius: 6px !important;
}
.radius-8px {
  border-radius: 8px !important;
}
.radius-10px {
  border-radius: 10px !important;
}
.radius-12px {
  border-radius: 12px !important;
}
.radius-16px {
  border-radius: 16px !important;
}

.border-gray-300 {
  border: 1px solid #8692A6 !important;
}

.pr-2rem{
  padding-right: 2rem;
}
.pl-2rem{
  padding-left: 2rem;
}

.tooltip-icon {
  color: #ff9800;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}
.tooltip-icon:hover {
  color: #ee8e00;
  transform: translateY(-1px);
}

/* End Custom CSS */
/*! Sweet alert color */
.swal-button{
  background-color: #03565A !important;
}
.swal-button:not([disabled]):hover{
  background-color: #04666A !important;
}
.swal-button--cancel{
  color: white;
}

.back-btn:hover{
  transform: scale(1.04) translateZ(0px);
  text-shadow: 0px 4px 8px #05959C;
  transition: 0.35s ease-in-out;
}

.table_td table tr td{
  border: none;
}

/* new material Ui css */
/* label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
}

.css-cev4hm-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 5px;
  border: none !important;
} */

/*@ashsis Overwrite mui css tab */
/*  */
/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100vw !important;
} */

@media screen and ( max-width : 1300px ){


  .RzCDL {
      margin-left: calc(54vw);
  }
  .cUbfEr {
      margin-left: calc(54vw);
  }
  .nav-tabs .nav-link {
      font-size: 14px;
  }
  .ck.ck-reset.ck-editor.ck-rounded-corners {
      width: 60%;
  }
  .tableSheet table {
      width: 60%;
  }
  .tableSheet table {
      width: 60%;
  }
  .containerQuestionnaire {
  
      margin: 0 auto;
      min-width: 84%;
  }
  
  
  }

  /* .Mui-checked {
    color: #03565A !important;
} */
    :root{
    --clr-bg:#F0DBC8;
    --clr-accent:#03565A;
    --clr-font-mid:#696F79;
    --clr-font-light:#D0D0D0;
    --clr-font-semi:#999999;
    --clr-form:#8692A6;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* typography,sizing,color and spacing */

.conatiner{
    display: flex;
    min-width: 800px;
    max-width: 1800px;
}

.column-1{
    padding: 40px 130px 0 130px;
    min-height: 100vh;
    max-height: 100%;
    min-width: 50%;
    max-width: 100%;
}
.column-2{
    background-color: #F0DBC8;
    background-color: var(--clr-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-height: 100%;
    min-width: 50%;
    max-width: 100%;
}
.column-2>img{
    height: 80%; 
    width: 80%; 
    object-fit: contain;
}
.pg-title{
    font-size: 14px;
    margin: 10px 0 5px 0;
}
.pg-title>h1{
    font-size: 2rem;
    font-weight: 600;
}

.password-signin{
    margin-top: 20px;
}

.emailId,.password-signin{
    display: flex;
    position: relative;
}
.emailId>h3,.password-signin>h3{
    font-weight: 500;
    margin-right: auto;
    font-size: 15px;
    color: #696F79;
    color: var(--clr-font-mid);
    margin-bottom: 0;
}


#error{
    border: 1.5px solid red;
    /* background-color: rgba(243, 32, 32, 0.199); */


}

input[type=text]:focus{
    border-radius: 6px;
    border: 1.3px solid #696F79;
    border: 1.3px solid var(--clr-font-mid);

} 

.signin-btn input[type="submit"]{
    padding: 20px 10px;
}

input[type=text] {
    /* text-align: center; */
    width: 100%;
    padding: 15px 10px;
    font-size: 16px;
    font-family: 'DM Sans';
    margin-top: 5px;
    display: inline-block;
    border: 1.3px solid #696F79;
    border: 1.3px solid var(--clr-font-mid);
    /* border-radius: 10px; */
  }
input[type=password] {
    width: 100%;
    padding: 15px 10px;
    font-size: 14px;
    font-family: 'DM Sans';
    margin-top: 5px;
    display: inline-block;
    border: 1.3px solid #696F79;
    border: 1.3px solid var(--clr-font-mid);
    border-radius: 6px;
  }



/* input[type=submit] {
    width: 100%;
    background-color:var(--clr-accent);
    color: white;
    padding: 23px 15px;
    font-size: 17px;
    font-family: 'DM Sans';
    margin: 10px 0;
    border: none;
    border-radius: 6px;
  } */
.cl-1{
      margin-right:4% ;
  }
  
.cl-1,.cl-2{
    width: 48%;
    position: relative
}
.cl-1>h3,.cl-2>h3{
    font-weight: 500;
    margin-right: auto;
    font-size: 15px;
    color: #696F79;
    color: var(--clr-font-mid);
}
/* .cl-1>i,.cl-2>i{
    color: var(--clr-accent);
    font-size: 1.5rem; 
    position: relative;
    right: -12rem;
    top: -2.5rem;
    padding: 1px;
    
    cursor: pointer;
} */
.cl-1>p,.cl-2>p{
margin-bottom: 0;
position: relative;
top: -20px;


}


input:focus, textarea:focus, select:focus{
    outline: none;
}
.cl-1>i::before,.cl-2>i::before{
    background-color: white;
    color: #03565A;
    color: var(--clr-accent);
    font-size: 1.5rem;
    position: relative;
    /* right: -80%;
    top: -2.5rem; */
    padding: 3px;
    cursor: pointer;
}
.cl-1>i,.cl-2>i{
    position: absolute;
    top:55px;
    right: 5px ;
}




.split{
    margin: 30px 0;

}
.password{
    margin-top: 20px;
}

.split>p {

    color:#D0D0D0;

    color:var(--clr-font-light);
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    border-bottom: 1.5px solid #D0D0D0;
    border-bottom: 1.5px solid var(--clr-font-light);
    line-height: 0.1em;
    margin: 10px 0 20px;
}
.split>p>span {
    background: #fff;
    padding: 0 20px;
}
.google-login>button{
    width: 100%;
    padding: 20px 15px 5px 15px;
    margin: 8px 0;
    border: 1.5px solid #D0D0D0;
    border-radius: 6px;
    cursor: pointer;
    background-color: white;

}
.google-login>button>p{
    margin-left: 25px;
    font-size: 1.15rem;
    font-weight: 400;
}
.google-login>button>i{
    color:#03565A;
    color:var(--clr-accent);
    font-size: 2rem
}
.forgotPassword{
    margin-top: 8px;
}

.forgotPassword>p{
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color:#03565A;
    color:var(--clr-accent);
    margin-bottom: 5px;
    margin-top: 5px;
}

.submit{
    margin-top: 8px;
}
.sign-up{
    margin-top: 0px;
}
.sign-up>a{
    font-weight: 500;
    text-decoration: none;
    -webkit-text-emphasis: none;
            text-emphasis: none;
    text-decoration: none;
    color: #696F79;
    color: var(--clr-font-mid);
    
}
.sign-up>a:hover{
    font-weight: 500;
    text-decoration: none;
    -webkit-text-emphasis: none;
            text-emphasis: none;
    text-decoration: none;
    color: #696F79;
    color: var(--clr-font-mid);
    
}
.sign-up>a>span{
    color: #03565A;
    color: var(--clr-accent);
}
.sign-up>a>span:hover{
    color: #03565A;
    color: var(--clr-accent);
    text-decoration: underline;
}
.terms{
    margin: 5px auto;
    align-content: center;
}
.terms>p{
    font-weight: 500;
    color:#696F79;
    color:var(--clr-font-mid);
    margin-top: 10px;
    margin-bottom: 10px;
}
.terms>a{
    font-weight: 500;
    margin:0 5px;
    color: black;

}

/*Modal css*/
.goback{
    margin-top: 40px;
}
.goback>a{
    font-weight: 500;
    text-decoration: none;
    color: #696F79;
    color: var(--clr-font-mid);
    
}
.goback>a>span{
    color: #03565A;
    color: var(--clr-accent);
}

.title>i{
    font-size: 4rem;
}

.modalemailId{
    margin-top: 75px;
}

.modalemailId>h1{
    font-size: 2rem;
    font-weight: 700;
}
.Modalemail>h1{
    margin-top: 40px;
    color: black;
    font-size: 1.9rem;
    font-weight: 700;

}
.Modalemail>p{
    color: #696F79;
    color: var(--clr-font-mid);
    font-weight: 500;

}

.sendLink{
    margin-top: 20px;
}
.password-signin>i::before{
    color: #03565A;
    color: var(--clr-accent);
    font-size: 20px;
    /* position: relative;
    right: -28rem;
    top: -2.5rem; */
    padding: 0px;
    
    cursor: pointer;
}
.password-signin>i{
    position: absolute;
    top: 40px;
    right: 5px;

}

/*google button*/

.google-button{
    width: 100%;
    justify-content: center;
    height: 60px;
    font-size: 1.1rem;
    box-shadow: none;
    border: 2px solid black;
    background-color: #c9ccd0!important;
}





.heading{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative ;
    top: -70px;
}
.chooseTemplate{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.blank,.existing{
    display: flex;
    flex-direction: column;
    justify-content: center;   
}
img{
    cursor: pointer;
}
.svg{
    display: flex;
    justify-content: center;
}
h{
    font-weight: 700;
}

p{
    align-self: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 1.1rem;
}

.modal-body {
    /* position: relative; */
    /* flex: 1 1 auto; */
    /* padding: 0rem; */
    min-height: 48vh;
    padding: 1rem;
}

.heading {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 20%);
    width: 70%;
    /* display: flex; */
}

.chooseTemplate {
    position: absolute;
    top: 35%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.containerQuestionnaire{
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    /* min-width: 1500px; */
}
.track{
    height: 100%;
    /* min-height: 100vh; */
    background-color: var(--clr-bg);
    display: flex;
    justify-content: center;
    padding-top: 10rem;
}
.progressBar{
    display: flex;

}
.track1{
    margin: 0 1rem 3.5rem 1rem;
}
.track1>h4{
    font-size: 1.2rem;
    font-weight: 600;
}
.track1>p{
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0;
}

.vector-1{
    width:42px ;
    height: 21rem;
    background-image: url(/static/media/sidebar-1.01307e2f.svg);
}
.vector-2{
    width:42px ;
    height: 21rem;
    background-image: url(/static/media/sidebar-2.936f369f.svg);
}

.vector-3{
    width:42px ;
    height: 21rem;
    background-image: url(/static/media/sidebar-3.3649bf6d.svg);

}

.form-control-lg{
    height: 49px;
    font-size: 1rem;
    border-radius: 6px;
}

.main{
    min-height: 100vh;
    padding:4rem;
}

.inputs{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tab-1,.tab-2{
    width: 340px;
}
.react-datepicker__input-container>input[type=text] {
    width: 100%;
    padding: 12px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    display: inline-block;
    border: none;
    border-radius: 6px;
    margin-top: 0;
    background: transparent;
}

/* .post>input[type=submit] {
    width: 100%;
    background-color: var(--clr-accent);
    color: white;
    padding: 23px 15px;
    font-size: 17px;
    font-family: 'DM Sans';
    margin: 10px 0;
    border: none;
    border-radius: 6px;
} */

.Date{
    border: 1px solid var(--clr-font-light);
    align-items: center;
    border-radius: 6px;
    height: 52px;

}
.Date>img{
   
    margin: 0 10px;

}
.post{
    padding: 0 12rem;
    margin-top: 4rem;
}
.header>h1{
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 50px;
}
.check-picker{
    margin-top: 4.5rem;
}

.suffix-format{
    font-size: 1rem;
    font-weight: 500;
    color: var(--clr-font-mid);
    text-align: center;
}
.sufix-small{
    display: flex;
    justify-content: center;
    color: var(--clr-font-semi);
}

.form-group>p,.in-1>p,.date-picker>p{
    margin: 0;
    margin-bottom: 0;
    font-size: 0.9rem;
    color: var(--clr-font-mid);
}
.form-group>p>span,.in-1>p>span,.date-picker>p>span{
    font-size: 1rem;
    color: red;
}
.in-1,.date-picker{
    margin-bottom: 25px;
}

.DnD{
    margin-top: 25px;
    height: 18rem ;
    border: 2px dashed var(--clr-accent);
    border-radius: 10px;


}

.folder{
   margin-top: 5rem;
   display: flex;
   justify-content: center;
}
.DnD>h3{
    text-align: center;
    font-size: 1.2rem;
    color: var(--clr-font-mid);
}
.DnD>p{
    text-align: center;
    font-size: 0.8rem;
    color: var(--clr-font-semi);
    margin-top: 0;
}
.file-show{
    display: flex;
    margin-top: 0rem;
}

.upload-TB{
    width: 50%;
    margin-left: 11rem;
    margin-top: 3rem;
    margin-bottom: 0;

}

@media (min-width: 992px){
    .modal-xl {
        max-width: 1100px;
    }
}

.input-map{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 3rem;
    grid-row-gap: 10px;
}


.map-input>Form>.form-group>.form-control-lg{
    height: 3rem;
    margin-top: 0;
    border: 1px solid var(--clr-font-light);
}

.mapping-container{
    padding: 0rem 2rem;
    padding: 2rem ;
    background-color: rgba(241, 241, 241, 0.863);
    border-radius: 10px;
}
.mapHeader>p{
    color: var(--clr-font-mid);
    font-weight: 400;
}

.map-input p{
    color: var(--clr-font-mid);
    font-weight: 400;
    margin: 0;
    font-size: 0.9rem;

}
.map-input p span{
    font-size: 1rem;
    color: red;

}
.map-input input[type=text] {
    width: 100%;
    padding: 9px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    margin-top: 0px;
    display: inline-block;
    border: 1.3px solid var(--clr-font-light);
    border-radius: 6px;


}

.map-input input[type=text]:focus-visible{
    outline: none;
    border: 2px solid #03565A;
    box-shadow: -1px -1px #cacacabf;

}

.map-button{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


.submit-btn{
    margin-left: 2rem;
}
.close-btn{
    font-weight: 600;
    color: var(--clr-accent);
    text-decoration: none;
    -webkit-text-emphasis: none;
            text-emphasis: none;
}
/* .submit-btn>input[type=submit] {
    width: 100%;
    background-color: blueviolet ;
    color: white;
    padding: 10px 40px;
    font-size: 17px;
    font-family: 'DM Sans';
    margin: 10px 0;
    border: none;
    border-radius: 6px;
} */

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0rem ;
}
.modal-content {
    border-radius: 0.8rem;
}

.file-name>div>h1{
    font-size: 2rem;
}
.file-name>div>p{
    padding: 0;
    margin-top: 0.5rem;
}

/* option {
    font-weight: normal;
} */


.modeHeader{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modeHeader>h1{
    display: flex;
    justify-content: center;
    font-size: 1.9rem;
    font-weight: 700;


}
.modeHeader>p{
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    color: var(--clr-font-mid);
    margin-bottom: 0;

}

.modeImg-1{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;


    background-image: url(/static/media/cMode.480e2d5a.svg);
}
.modeImg-2{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;

    background-image: url(/static/media/tMode.5031b8d2.svg);
}

.modeImg-2:hover{
    border: 2px solid var(--clr-accent);

}
.modeImg-1:hover{
    border: 2px solid var(--clr-accent);

}


.modeImg-2_active{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;
    border: 2px solid #03565a;
    background-image: url(/static/media/tMode.5031b8d2.svg);

}
.modeImg-1_active{
    height: 10.8rem;
    width: 17.5rem;
    border: 1px solid var(--clr-font-light);
    border-radius: 1rem;
    cursor: pointer;
    border: 2px solid #03565a;
    background-image: url(/static/media/cMode.480e2d5a.svg);

}
.in-1>input{
    height: 48px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #cacacabf;
    padding: 10px;
}
.in-1>input:focus-visible{
    outline: none;
    border: 2px solid #03565A;
    box-shadow: -1px -1px #cacacabf;
}


.cMode,.tMode{
    margin-top: 2rem;
    display: flex;
}

.modeContent{
    padding: 2rem;
}
.modeContent>h1{
    font-size: 1.1rem;
    font-weight: 600;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 8px;
    margin-right: .3125rem;
    margin-left: 0;
}

.modeContent>p{
    margin: 0;
    font-size: 0.8rem;
    color: var(--clr-font-semi);
    font-weight: 400;
}
/*select styling*/

.react-date-picker__wrapper{
    height: 48px;
    border-radius: 6px;
    width: 21rem;
    /* border-color: hsl(0, 0%, 80%); */
    border: 1px solid var(--clr-font-light) !important;
}



.conventionalMenu{
    display: flex;
    align-items: center;
    height: 58px;
    position:relative;
    background-color: var(--clr-bg);
    box-shadow: 4px 4px 8px #8b8b8b38 ;
    /* -webkit-box-shadow:4px 4px 8px #504f4f5d; */
    padding: 0 15px 0 10px;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    min-width: 1045px;

}
@media (max-width: 1100px) {
    .conventionalMenu div p{
        display: none;
    
    }
    .conventionalMenu  div{
        margin: 1rem;
    }

    
  }

.conventionnalTable{
    /* box-shadow: -4px -4px 8px #5858586c ; */
}

.conventionalMenu div{
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    cursor:pointer;
}

.filter{
    cursor: pointer;
}

.conventionalMenu div p{
    margin-top: 16px;
    margin-left: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;

}

.uploadConventional{
    cursor:pointer
}

.delete i{
    cursor: pointer;
    color: rgba(207, 7, 7, 0.815);
}
.delete span{
    color: rgba(194, 13, 13, 0.815);
    cursor: pointer;
    font-size: 14px;
}

.conventionalMenu div:last-child{
    margin-left: auto;
    margin-right: 0;
}

.conventionalMenu div:hover{

    /* text-decoration: underline; */
}
.conventionalMenu div:last-child:hover{

    text-decoration: none;
}

.addTb>h4{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: right;
    margin: 0;
    color: var(--clr-accent);
    
}

.rowHeader{
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns:  repeat(2, 11.6%) repeat(3, 7.6%) 11.6% repeat(1, 7.6%) repeat(3, 11.6%);

    /* grid-template-columns: auto auto auto auto auto auto auto auto auto auto; */
    /* background-color: cadetblue; */
}

.rowHeader div{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--clr-font-light);
    border-top: none;
    border-left: none;
    background-color:  #F6F6F6;
}

.ledgerCode input[type=checkbox]{
    box-sizing: border-box;
    margin-top: 5px;
    margin-right: 10px;
}

.rowHeader div p{
    color: var(--clr-font-mid);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: left;

}

.tabsinside>div{
    height:77vh
}
/* .addLedger{
    width: 100%;
    height: 2.5rem;
    padding: 10px;
    background-color: #f1ede9d9;
    position:fixed;
    bottom:'10px'
}
 */

.conventionalTable{
  overflow-x:scroll;
  height: 70vh;
}

.conventionalTable table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }
 

  .conventionalTab table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }
 
  
  

.conventionalTable th{
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px;
  background-color: #cecece;
  color: rgb(70, 70, 71);
  font-size: 13px;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top:0px;
  z-index: 4;
}


/* .conventionalTab{
  max-height: 80vh;
  overflow-y: scroll;
} */


.conventionalTab th{
  border: 1px solid #f5f5f5;
  text-align: center;
  padding: 10px;
  color: #717171;
  font-size: 13px;
  background-color: #f8f8f8;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top:0px;
  z-index: 9;
}

.highlighted{
  /* border: 2px dotted var(--clr-accent); */
  box-shadow: 0px 5px 5px rgba(3, 86, 90 , 0.6);
  background-color: rgba(3, 86, 90 , 0.8  ) ;
  color:white
  /* border: 2px inset rgba(68, 65, 65, 0.979); */
}

.btn{
  padding: 0.75em;
}


/* .conventionalTable td {
  border: 1px solid #dddddd;
  text-align: center;
  font-size: 16px;
  color: #9e9898;
  height: 50px;
  background-color: white;
} */


/* option {
  padding: 10px;
  font-size: 16px;
  background-color: beige;
}

option:before {
  content: ">";
  font-size: 20px;
  display: none;
  padding-right: 10px;
  padding-left: 5px;
  color: rgb(129, 59, 59);
} */


.form-control {
  text-overflow: ellipsis;
  white-space: nowrap;  
  overflow: hidden;
  color: black !important;
  background-color: transparent;

  outline: none;
  border: none;
  border-radius: 0rem;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  height: 15px;
  width: 15px;
  margin: 10px;
}

#dropdown-item-button{
  text-align: center;
  z-index: 0;
  background-color: transparent;
  color: var(--clr-font-mid);
  height: 100%;
  width: 100%;
  border: none;
  -webkit-text-size-adjust: 14;
     -moz-text-size-adjust: 14;
          text-size-adjust: 14;
  
}

.drop-btn{
  max-width: 200px;
}

#dropdown-item-button:hover{
  background-color: transparent;
  /* color: black; */


}

#dropdown-item-button>text{
  color:'black';
  font-style: italic;

}

.highlightedRow{
  background-color: rgba(135,206,235,1);
}

.selectedRow{
  background-color: #ffe7d1;
}

.selectedSingleRow{
  background-color: #c9facb;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'DM Sans';
  width: 100%;
  }
 
  
  
td {
    border: 1px solid #dddddd;
    text-align: center;
    /* padding: 10px; */
    /* font-size: 16px; */
    /* color: var(--clr-font-mid); */
    height: 45px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
th{
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px;
  /* background-color: #dddddd79; */
  color: rgb(71, 70, 70);
  font-size: 16px;
  height: 45px;
}

tr:hover{
  background-color: rgba(105, 122, 117, 0.062);
}

thead{
  
}


/* option {
  padding: 10px;
  font-size: 16px;
  background-color: beige;
}

option:before {
  content: ">";
  font-size: 20px;
  display: none;
  padding-right: 10px;
  padding-left: 5px;
  color: rgb(129, 59, 59);
} */


.form-control {
  text-overflow: ellipsis;
  white-space: nowrap;  
  overflow: hidden;
  color: grey;
  background-color: transparent;

  outline: none;
  border: none;
  border-radius: 0rem;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  height: 15px;
  width: 15px;
  margin: 10px;
}

#containerDiv::-webkit-scrollbar {
  width: 10px;
}
 
#containerDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
#containerDiv::-webkit-scrollbar-thumb {
  background-color: #03565A;
  border-radius: 10px;
}

.tableDeleted{
  max-height: 17rem;
  min-height: 17rem;
  overflow-y: auto;
}

.Ledger input{
    /* background : var(--clr-accent); */
    border: 1px solid #cacacabf;

} 

.Ledger>input:focus-visible{
    outline: none;
    border: 2px solid #03565A;
    box-shadow: -1px -1px #cacacabf;
}
#list::-webkit-scrollbar {
    width: 5px;
  }
   
  #list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
   
  #list::-webkit-scrollbar-thumb {
    background-color: #03565A;
    border-radius: 10px;
  }
.MuiBox-rootTab>div>div {
    padding: 0px;

}

.dragtemplate{
    background-color: #03565aad;
    /* border: 2px solid var(--clr-accent); */
}

.react-abc{
    text-align: start;
 }
#TemplateScroll::-webkit-scrollbar {
    width: 10px;
  }
   
#TemplateScroll::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
#TemplateScroll::-webkit-scrollbar-thumb {
    background-color: #03565A;
    outline: 1px solid white;
  }

  /* .wrapper {
    border: 3px solid;
  } */
  
  /* .container {
    background-color: pink;
  } */
  
  /* .barCompleted {
    background-color: red;
    width: 80%;
  }
  
  .label {
      font-size: 20px;
      color: green;
    } */

.previewCard{
    /* height:15rem; */
    width:100%;
    background-color: #FDFDFD;
    border-radius: 6px;
    padding: 2rem;
    cursor:pointer;
    position: relative;
    
}

.previewCard:hover{
    box-shadow: 2px 2px 10px #5858584b ;
    transition: ease-out;
    transition: 300ms;
}
.previewCardv2{
    /* height:15rem; */
    width:100%;
    background-color: #F2F3F4 ;
    border-radius: 6px;
    padding: 2rem;
    cursor:pointer;
    position: relative;
    
}

/* .previewCardv2:hover{
    box-shadow: 2px 2px 10px #5858584b ;
    transition: ease-out;
    transition: 300ms;
} */

#gridScroll{
    display:grid;
    grid-template-columns:repeat(4, 23%);
    grid-gap:2rem;
    margin:1rem 0;
    max-height:70vh;
    overflow-y:scroll;
    padding:10px 2rem 0;
}


@media (max-width: 1100px){
    #gridScroll{
        grid-template-columns: repeat(3, 30%);
    }
}

#gridScroll::-webkit-scrollbar {
  width: 6px;
}
 
#gridScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
#gridScroll::-webkit-scrollbar-thumb {
  background-color: #03565abb ;
  border-radius: 10px;
}


#groupingScreen::-webkit-scrollbar {
  width: 6px;
}
 
#groupingScreen::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
#groupingScreen::-webkit-scrollbar-thumb {
  background-color: #03565abb;
  border-radius: 10px;
}

.classificationTable table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}




.classificationTable th{
border: 1px solid #dddddd;
text-align: center;
padding: 10px;
background-color: #cecece;
color: rgb(70, 70, 71);
font-size: 13px;
height: 50px;
position: -webkit-sticky;
position: sticky;
top:1px
}

.preview-page td{
  text-align: left;
  padding: 0px 10px;
}
.nav-tabs .nav-link {
    margin-right: 10px;
}

.nav a {
    color:#495057 ;
    font-weight: 450;

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--clr-accent);
    background-color: #fff; 
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid #b1acac65;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.paneltab{
    border:1px solid #D6D6D6 !important;
    border-bottom: 0 !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    color:black !important;
    min-width: 70px !important;
}
.paneltab.Mui-selected{
    color: #03565a !important;
    border: none !important;
    background-color: #fff !important;
    opacity: 1;
}
.paneltabcolor{
    color:black !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: black ;
}
.bsnotetab button{
    text-align: left;
}
.bsnotetab .MuiTab-wrapper{
    display: inline-block;
}
.custom-placeholder:-ms-input-placeholder {
    color: black;
  }
.custom-placeholder::placeholder {
    color: black;
  }


/* ? @link generate class name */
.hyper-link-ref {
    text-decoration: underline;
    -webkit-text-decoration-color: lightblue;
            text-decoration-color: lightblue;
}
.tableSheet thead{
    height:90px;
    background: #F8FAFB;

}

.tableSheet th{
    border: 1.5px solid #D9D9D9; 
    background: #F8FAFB;    
    border-top: none;
    border-bottom: none;
    /* font-family: Inter; */
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;

    
}


ableSheet th:nth-child(1){
    text-align: left;
    padding-left: 3%;
}
    
.tableSheet th:last-child{
    text-align: left;
    padding-left: 3%;
}
    

.tableSheet td{
    border: 1.5px solid #dddddd; 
    border-top: none;
    border-bottom: none;   
    color: black;
}
.tableSheet tr:hover{
    background-color: white;
}
.tableSheet td:nth-child(1){
    text-align: left;
    padding-left: 3%;
    cursor: pointer;
}
.tableSheet td:nth-child(3){
    text-align: right;
    padding-right: 2%;
    padding-left: 1%;
}
.tableSheet td:nth-child(4){
    text-align: right;
    padding-right: 2%;
    padding-left: 1%;
}
.tableSheet td:last-child{
    text-align: center;
    padding-left: 1%;
}

.notes_numbering{
    color:var(--clr-accent);
    line-height: 20px;
    font-size:16px;
    font-weight:500;
    cursor:pointer;
}


.notes_numbering:hover{
    color:var(--clr-accent);
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;

}

.ck.ck-editor:{
    position: fixed;
}
.tableSheet thead{
    height:90px;
    background: #F8FAFB;

}

.tableSheet th{
    border: 1.5px solid #D9D9D9; 
    background: #F8FAFB;    
    border-top: none;
    border-bottom: none;
    /* font-family: Inter; */
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;

    
}



.tableSheet th:nth-child(1){
    text-align: left;
    padding-left: 3%;
}
    
.tableSheet th:last-child{
    text-align: left;
    padding-left: 3%;
}
    

.tableSheet td{
    border: 1.5px solid #dddddd; 
    border-top: none;
    border-bottom: none;   
    color: black;
}
.tableSheet tr:hover{
    background-color: white;
}
.tableSheet td:nth-child(1){
    text-align: left;
    padding-left: 3%;
    cursor: pointer;
}
.tableSheet td:nth-child(3){
    text-align: right;
    padding-right: 2%;
    padding-left: 1%;
}
.tableSheet td:nth-child(4){
    text-align: right;
    padding-right: 2%;
    padding-left: 1%;
}
.tableSheet td:last-child{
    text-align: center;
    padding-left: 1%;
}
.MuiTab-root{
    color: inherit;
    opacity: 0.6;
    font-size: 3rem;

}

.MuiTab-wrapper{
    font-size: 1rem;
}
.MuiTab-root {
    padding: 12px 12px;
    overflow: hidden;
    position: relative;
    font-size: 1rem;
    max-width: 400px;
    min-width: 200px;
    box-sizing: border-box;
    min-height: 57px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.some{
    background-color: black;
}

.makeStyles-tabs-2 div{
    width: 100%;
}

/* 
.MuiTabs-root makeStyles-tabs-2 MuiTabs-vertical{
    width: 220px;
} */


.n4level3 td{
    color:black;
    font-size: 18px;
    font-weight: 500;
    border-top: none;
    border-bottom: none;
    padding-top: 2rem;
    text-align: end;
    padding-right:1rem
}


.n4disclosure td{
    color:black;
    font-size: 16px;
    font-weight: 400;
    border-top: none;
    height: 33px;
}

.n4addInput td{
    font-weight: 600;
    color:var(--clr-accent);
    border-top: none;

}


.table-row{
    width: 10%;
    text-align:end;
    padding-right: 1rem;
}
.table-row-input{
    border: none;
    text-align: end;
    background-color: inherit;
}
.table-row-header-input{
    border: none !important;
    padding: 0px !important;
    text-align: start;
    background-color: inherit;
}
.table-row-header-total {
    text-align: start;
    padding-left: 2rem;
}

.table-row-input-1{
    width: 5rem;
    border: none;
    text-align: end;
    background-color: inherit;
}
.table-row-header-input-1{
    width: 5rem;
    border: none !important;
    padding: 0px !important;
    text-align: start;
    background-color: inherit;
}

.table-row-calculate-total {
    background-color: #f0dbc8;
    font-weight: 600;
}

.table-row-sub-total {
    width: 10%;
    text-align:end;
    padding-right: 2rem;
}
/* Scroll top tab */
.top-btn {
  width: 3rem;
  height: 3rem;
  color: #03565a;
  /* background-color: #fff; */
  border-radius: 50%;
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  transition: transform 0.3s ease-in-out;
}

.top-btn:hover {
  transform: scale(1.1);
}

/* Primary button */
.primary-button {
  box-shadow: #b3d2d4 0px 2px 5px -1px, #03565a 0px 1px 3px -1px !important;
  transition: box-shadow 0.3s, transform 0.3s;
}

.primary-button:hover {
  box-shadow: #66a2a4 0px 4px 10px -1px, #03565a 0px 2px 5px -1px !important;
  transform: scale(1.01) translateY(-0.5px);
  transition: 0.15s ease-in-out;
}

/* Tool tip */
/* CommonToolTip.css */
.commontooltip {
  position: relative;
  display: inline-block;
}

.commontooltip .commontooltiptext {
  visibility: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 500;
  opacity: 0; 
  transition: opacity 0.3s ease-in-out;
}

.commontooltip:hover .commontooltiptext {
  visibility: visible;
  opacity: 1; 
}

/* Variants */
.primary {
  color: #6e6e6e;
  background: rgba( 219, 210, 225, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.error {
  color: #ff4757; 
  background: rgba( 255, 71, 87, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.success {
  color: green;
  background: rgba( 46, 213, 115, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.warning {
  color: #ffa502;
  background: rgba( 255, 213, 138, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 5px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* Placement */
.top {
  bottom: 100%;
  left: -85%;
  transform: translateX(-50%);
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}
.nbfc-box-shadow {
    margin: 1rem;
    box-shadow: 0px 1px 4px 1px rgba(162,157,157,0.75);
}
.curtomBtn:hover{
    background-color: #04666a !important;
}
.border-ratio{
    border-color: #03565a !important;
}
.border-error{
    border-color: red !important;
}
.financialInstrument-deleteIconHover{
    filter: drop-shadow(0px 3px 2px #999);
}

.financialInstrument-deleteIconHover:hover{
    color: #f33;
    filter: drop-shadow(0px -2px 3px #F66);
    transform: scale(1.2) translateZ(0px);
    transition: 0.35s ease-in-out;
}
.financialInstrument-restoreIconHover{
    color: #00b327;
}
.financialInstrument-restoreIconHover :hover{
    transition: 0.10s ease-in-out;
    filter: drop-shadow(0px -2px 5px #2bff59);
}
.financialInstrument-hoverIcon{
    filter: drop-shadow(0px 4px 3px #FDE507);
}
.financialInstrument-hoverIcon:hover{
    color: #dd7900;
    transform: scale(1.1) translateZ(0px);
    transition: 0.35s ease-in-out;
    filter: drop-shadow(0px -2px 5px #FDE507);
}

.deleteBtn{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
}
.deleteBtn:hover{
    transform: scale(1.01) ;
    transition: 0.15s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 3px 0px, rgba(0, 0, 0, 0.3) 1px 2px 0px -1px;
}

.financial-instrument-note{
    color: #04666a;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
}
.financial-instrument-note:hover{
    font-size: 1.03rem;
    transition: 0.10s ease-in-out;
    text-shadow: 0px 0px 1px #03565a;
}

.financial-restore-modal{
    /* text-align: center !important; */
    font-size: 1.5rem !important;
    color: #03565a !important;
}
.css-1cuxlhl {
    display: none !important;
}

.labelh1{
    font-weight: 600;
    font-size: 18px;
}

.p-table{
    font-weight: 800;
    font-size: 16px;
    color: #03565A;
}
.p-secondtable{
    color: gray;
    text-align: left;
    margin-left: 11px;
    margin-top: 9px;   
}

.p-thirdtable{
    color: black;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;
}

.light-blue{
    background-color: #CDDDDE;
}

.tb-panel{
    max-height:36rem;
    overflow-y: auto;
}

.add-remark{
    font-size: x-small;
    font-weight: 300;
}
.dm-sans{
    font-family: 'DM Sans', sans-serif;

}

.fs-inter{
    font-family: 'Inter' !important;
}
.fs-Manrope{
    font-family: 'Manrope', sans-serif;
}
/* .socie-table-container {
    overflow-x: auto;
}

.socie-responsive-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.socie-responsive-table th,
.socie-responsive-table td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {

    .socie-responsive-table th,
    .socie-responsive-table td {
        white-space: normal;
    }
} */
.socie-header{
    min-width: 320px;
}
.socie-amount-header{
    min-width: 160px;
}
.adjustmentsMenu{
    display: flex;
    align-items: center;
    height: 58px;
    position:relative;
    background-color: var(--clr-bg);
    box-shadow: 4px 4px 8px #8b8b8b38 ;
    /* -webkit-box-shadow:4px 4px 8px #504f4f5d; */
    padding: 0 1rem 0 1rem;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    min-width: 1045px;
}

.adjustmentsMenu div{
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    cursor:pointer;
}

.adjustmentsMenu div p{
    margin-top: 16px;
    margin-left: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-align: left;

}

@media (max-width: 1100px) {
    .adjustmentsMenu div p{
        display: none;
    
    }
    .adjustmentsMenu  div{
        margin: 1rem;
    }

    
  }

  .adjustmentsMenu div:hover{

    text-decoration: underline;
}



.adjustmentsTable th{
    border: 1px solid #dddddd;
    text-align: center;
    padding: 10px;
    background-color: #F8F8F8;
    color: #717171;
    font-size: 14px;
    height: 62px;
    font-weight: 600;
    position: -webkit-sticky;
    position: sticky;
    top:0
  }
.adjustmentsTable tr{
    /* padding: 10px; */
    font-size: 14px;
    /* height: 62px; */
    font-weight: 400;
    color: black;

  }
.adjustmentsTable td{
    /* padding: 10px; */
    font-size: 17px;
    /* height: 62px; */
    font-weight: 400;
    color: var(--clr-font-mid);

  }


  .adjustmentsTable::-webkit-scrollbar {
    width: 10px;
  }
   
  .adjustmentsTable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .adjustmentsTable::-webkit-scrollbar-thumb {
    background-color: #03565A;
    outline: 1px solid white;
  }

  /* new adj */
  .records {
    width: 100%;
    display: flex;
    height: 35px;
    background-color: lightgray;
    border: 2px solid #D0D0D0;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 0;
  }
.dropdown-menu{
    overflow-y: scroll;
    height: 15rem;
    scroll-behavior: smooth;
}

.projectcard{
    /* cursor: pointer; */
    background-color: white;
}

.projectcard::-webkit-scrollbar {
    width: 8px;
  }
   
.projectcard::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.projectcard::-webkit-scrollbar-thumb {
background-color: #03565A;
border-radius: 20px;
outline: 1px solid white;
}

.projectcard:hover{
    /* background-color: blueviolet; */
    box-shadow: 2px 3px 15px rgb(139, 139, 139);

}
.logout_btn:hover{
    /* box-shadow: 2px 3px 15px rgb(139, 139, 139); */
    color:white;
    text-decoration: underline;
    /* outline: 1px solid white; */
}

.back:hover{
    text-decoration: underline;
    cursor: pointer;

}
.iconEnter>i:hover{
    color:'green';
    transform: scale(1.5);
}
.iconEnter{
    background-color:'green';
}
.searchInput>i::before{
    position: relative;
    right:2rem
}

#gridScroll2{
    display:grid;
    grid-template-columns:repeat(3, 30%);
    grid-gap:2rem 4rem;
    margin:0rem 0;
    max-height:70vh;
    overflow-y:scroll;
    padding:0 2rem;
}


@media (max-width: 1100px){
    #gridScroll2{
        grid-template-columns: repeat(1, 60%);
    }
}


#gridScroll2::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
   
#gridScroll2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
  
#gridScroll2::-webkit-scrollbar-thumb {
  background-color: #03565A;
  outline: 1px solid white;
  border-radius: 10px
}


.viewcard{
    width:900px;
    /* background-color: '#FFFFFF'; */
}

.describeproblem{
    border-radius: 8px;
    border: 1px solid #8692A6;
    background-color: #F8F8F8;
    height: 170px;
}
.borderdashed{
    border-radius: 16px;
    border: 2px solid #8692A6;
    background-color: #F8F8F8;
    padding: 80px;
}
.borderdashed2{
  border-radius: 16px;
  border: 2px solid #8692A6;
  background-color: #F8F8F8;
}

.new-textinput{
    height: 56px;
    background-color:#F8F8F8 ;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
  }

  .tablesupport {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    border: 1px solid rgba(205, 205, 205, 1);
}

.custom-table thead th:first-child {
    border-top-left-radius: 12px;
  }
  
  .custom-table thead th:last-child {
    border-top-right-radius: 12px;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 15px;
  }
  .custom-table th{
    background-color: "#D6D6D6";
  }
  
  .custom-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .tablesupport {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    border: 1px solid rgba(205, 205, 205, 1);
} 
  
  .custom-table .last-row td:first-child {
    border-bottom-left-radius: 12px;
  }
  
  .custom-table .last-row td:last-child {
    border-bottom-right-radius: 12px;
  }
  
  .last-row{
    background-color: white;
  }
/* .border{
    height: 55px;
    border:1px solid #C2C9D1;
    border-radius: 5px;
} */

.assignagent{
    width: 120px;
    height: 50px;
    border-radius: 5px;   
}

.select-btn>.select-colum {
    width: 170px;
    background-color: white;
    border: 2px solid #03565A;
    padding: 15px;
}
.ticketcard{
    width: 450px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #D6D6D6;
    background-color: #FFFFFF;
}
.closeticket{
    border: 2px solid #03565A;
    color: #03565A;
    text-align: center;
    border-radius: 12px;
    padding: 15px;
}
.new-textinput{
    background-color: #F8F8F8;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
}


.logo-container {
    display: flex;
    align-items: center;
}

.logo-box {
    width: 50px;
    height: 50px;
    background-color: #D9BF66;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 10px;
}
.logo-text {
    font-size: 16px;
    color: black;
}

.message-input {
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .message-input input {
    border: none;
    outline: none;
    flex: 1 1;
    font-size: 16px;
    background-color: transparent;
  }
  
  .icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #D6D6D6;
    cursor: pointer;
  }
  
  .icon-button img {
    width: 24px;
    height: 24px;
  }

  /* .scroll-container::-webkit-scrollbar {
    display: none;
  }
   */

   .superadmin-bg {
    background-color:#D9BF66 ; /* Change to desired color */
  }
  
  .admin-bg {
    background-color:#03565A; /* Change to desired color */
  }

  .fixed-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    border-bottom: 1px solid #ddd;
    padding: 10px;
  }

  .scrollable-chat-content {
    height:340px; /* Adjust based on header height */
    overflow-y: auto;
    padding-top: 20px;
  }

  .date-label-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .date-label-container hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin-right: 20px;
  }
  
  .date-label-container .date-label {
    position: absolute;
    background-color: white;
    padding: 0 10px;
    font-size: 10px;
    color: gray;
  }

  .Table-headerText{
    color:  #717171;
  }
  /* .custom-font-500 {
    font-weight: 500 !important;
} */
.page-wrapper.compact-wrapper .page-header {
    margin-left: 280px;
    width: calc(100% - 280px);
}

.page-wrapper {
    overflow: hidden;
}

.page-wrapper .page-header .header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 22px 30px;
    position: relative;
}
.logo-wrapper, .page-wrapper .page-header {
    background: rgb(3, 86, 90);
    position: fixed;
    width: 100%;
    z-index: 6;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
    display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 80px);
    margin-top: 80px;
}
.page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 136px);
    margin-top: 136px;
    padding: 0 15px 0 15px;
    position: relative;
    transition: 0.5s;
}
.page-wrapper .page-body-wrapper .page-title {
    padding-top: 30px;
    padding-bottom: 30px;
}

.page-wrapper .page-body-wrapper .page-title {
    padding-top: 30px;
    padding-bottom: 30px;
}
.card1 {
    margin-bottom: 30px;
    border: none;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgb(8 21 66 / 5%);
}

.default-according .card1{
    margin-bottom: 0;
    box-shadow: none;
}
.btn-primary1 {
    background-color: #03565a !important;
    border-color: #03565a !important;
    color: #fff;
}
button.btn-close {
    border: none;
    background: transparent;
    color: #000;
    font-size: 20px;
}
.ps-5 {
    padding-left: 3rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}
.float-end {
    float: right;
}
.btn1 {
    padding: 0.375rem 1.75rem;
}
.default-according .card .card-header {
    padding: 0;
    border: none;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.bg-primary1 {
    background-color: #03565a !important;
    color: #fff;
}

.default-according .card .btn-link {
    font-weight: 500;
    color: #2b2b2b;
    text-decoration: none;
}

.default-according .card .card-header.bg-primary .btn, .default-according .card .card-header.bg-secondary .btn {
    border-color: rgba(0, 0, 0, 0);
}
.default-according .card .card-header .btn-link {
    padding: 12px 20px;
    width: 100%;
    text-align: left;
    letter-spacing: 0.7px;
    font-family: Roboto;
    border: 1px solid #efefef;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
    margin-left: 15px;
}
.media.profile-media .media-body, .media.profile-media .media-body p.mb-0.font-roboto {
    color: #fff;
}
.media .media-body {
    flex: 1 1;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body span {
    font-weight: 500;
}
.b-r-10 {
    border-radius: 10px !important;
}
.media {
    display: flex;
    align-items: flex-start;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
    font-size: 12px;
    line-height: 1;
    color: #fff;
}
.middle {
    vertical-align: middle;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    width: 160px;
    top: 57px;
    left: -12px;
    padding: 0 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: 330px;
    top: 50px;
    right: 0;
    left: unset;
}
.onhover-show-div {
    box-shadow: 0 0 20px rgb(89 102 122 / 10%);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
}
.onhover-show-div {
    top: 80px;
    position: absolute;
    z-index: 8;
    background-color: #fff;
    transition: all linear 0.3s;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
    padding: 10px !important;
}
.card1 .card-body1 {
    padding: 40px;
    background-color: rgba(0, 0, 0, 0);
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body{
    margin-top:30px;
    }
    
    .page-wrapper .page-body-wrapper {
        background-color: #f8f8f8;
    }
    button.btn.btn-primary1{
    color: #fff;
    }
    
    .card1 .card-header {
        background-color: #fff;
        padding: 40px;
        border-bottom: 1px solid #ecf3fa;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
    }
    
    .card1 .card-body {
        padding: 40px;
        background-color: rgba(0, 0, 0, 0);
    }
    
    
    .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body {
        margin-left: 15px;
    }
    .media .media-body {
        flex: 1 1;
    }
    .media.profile-media .media-body, .media.profile-media .media-body p.mb-0.font-roboto {
        color: #fff;
    }
    
    .media {
        display: flex;
        align-items: flex-start;
    }
    .onhover-dropdown {
        cursor: pointer;
        position: relative;
    }
    .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
        padding: 6px 10px;
        margin-right: 5px;
        display: inline-block;
        position: relative;
    }
    .page-wrapper .page-header .header-wrapper .nav-right > ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        justify-content: flex-end;
    }
    .ps-0 {
        padding-left: 0 !important;
    }
    .page-wrapper .page-header .header-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        position: relative;
    }
    ul {
        padding-left: 0px;
        list-style-type: none;
        margin-bottom: 0;
    }
    .nav-right {
        justify-content: flex-end;
    }
    .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
        display: flex;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        justify-content: flex-end;
    }
    .page-wrapper .page-header {
        max-width: 100vw;
        position: fixed;
        top: 0;
        z-index: 8;
        transition: 0.5s;
        /* background-color: #fff; */
        box-shadow: 0 0 20px rgb(89 102 122 / 10%);
    }
    
    .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
        padding: 23px 30px;
        box-shadow: -9px 0 20px rgb(89 102 122 / 10%);
    }
    .pull-right { float: right;}
    
    .page-wrapper .page-body-wrapper .page-title .breadcrumb {
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;
    }
    
    
    body.dark-only {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
        background: #262932;
        color: #fff;
    }
    body.dark-only .card {
        background-color: #262932;
    }
    body.dark-only .ecommerce-widget {
        border: 1px solid #374558 !important;
    }
    body.dark-only .btn-light {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .form-select {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .add-project .text-inherit {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .knowledgebase-search {
        color: #2b2b2b;
    }
    body.dark-only .knowledgebase-search .form-control-plaintext {
        color: #2b2b2b;
    }
    body.dark-only .btn-close {
        filter: brightness(0.8) invert(1);
    }
    body.dark-only .prooduct-details-box .media {
        border: 1px solid #374558;
    }
    body.dark-only .product-price del {
        color: #98a6ad;
    }
    body.dark-only .ProfileCard {
        border: 1px solid #374558 !important;
    }
    body.dark-only .form-control-plaintext {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .form-select {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .box-layout {
        background-color: #1d1e26;
    }
    body.dark-only .box-layout .page-wrapper .page-header .header-wrapper,
    body.dark-only .box-layout.page-wrapper .page-header .header-wrapper {
        border: 1px solid #1d1e26;
    }
    body.dark-only .form-check-input {
        background-color: #1d1e26;
    }
    body.dark-only .switch-state:before {
        background-color: #262932;
    }
    body.dark-only .daterangepicker:before {
        border-bottom-color: #262932;
    }
    body.dark-only .daterangepicker .ranges li {
        background-color: #1d1e26;
        border-color: #262932;
        color: #898989;
    }
    body.dark-only .daterangepicker .daterangepicker_input .form-control {
        background-color: #1d1e26;
        border-color: #262932 !important;
    }
    body.dark-only .daterangepicker .calendar-time select {
        border-color: #262932;
        background-color: #1d1e26;
    }
    body.dark-only .daterangepicker.dropdown-menu {
        background-color: #262932;
        box-shadow: 0 0 14px #1d1e26;
    }
    body.dark-only .daterangepicker .calendar-table {
        background-color: #262932;
        border-color: #262932;
    }
    body.dark-only .daterangepicker.ltr td.in-range,
    body.dark-only .daterangepicker.ltr td.off {
        background-color: #1d1e26;
        color: #fff;
    }
    body.dark-only .tooltip.bs-tooltip-top .tooltip-arrow:before {
        border-top-color: #323846;
    }
    body.dark-only .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
        border-bottom-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip.bs-tooltip-start .tooltip-arrow:before {
        border-left-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip.bs-tooltip-end .tooltip-arrow:before {
        border-right-color: #323846;
        border-top-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .tooltip .tooltip-inner {
        background-color: #323846;
        color: #fff;
    }
    body.dark-only .tooltip .tooltip-arrow:before {
        border-top-color: #323846;
    }
    body.dark-only .page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6:first-child h6 {
        color: rgba(255, 255, 255, 0.5);
    }
    body.dark-only .page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
        background-color: #1d1e26;
        border: 1px solid #374558;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:last-child {
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
        background-color: #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:not(.profile-dropdown) li:first-child {
        background-color: #262932;
    }
    body.dark-only .customizer-contain {
        color: #1d1e26;
    }
    body.dark-only .customizer-contain .customizer-body .main-layout .box-layout {
        background-color: #fff;
    }
    body.dark-only .login-card .btn-showcase .btn {
        background-color: #262932 !important;
        color: #fff;
        border-color: #262932 !important;
    }
    body.dark-only .login-card .login-main {
        box-shadow: 0 0 37px rgba(255, 255, 255, 0.05);
    }
    body.dark-only .login-card .login-main .theme-form input {
        background-color: #262932 !important;
    }
    body.dark-only .login-card .login-main .theme-form .or:before {
        background-color: #262932;
    }
    body.dark-only .login-card .login-main .theme-form .checkbox label::before {
        background-color: #262932 !important;
        border-color: #262932 !important;
    }
    body.dark-only .H_ui ~ div {
        background-color: #1d1e26 !important;
    }
    body.dark-only .swal-icon--success:after,
    body.dark-only .swal-icon--success:before {
        background: #262932;
    }
    body.dark-only .swal-icon--success__hide-corners {
        background-color: #262932;
    }
    body.dark-only .note-editor.note-frame .note-status-output {
        border-top: 1px solid #262932;
    }
    body.dark-only .note-editor.note-frame .note-statusbar {
        border-top: 1px solid #262932;
    }
    body.dark-only .note-editor.note-frame .note-statusbar .note-resizebar {
        background-color: #262932;
    }
    body.dark-only .light-font {
        color: #fff;
    }
    body.dark-only .page-link {
        background-color: #1d1e26;
        border: 1px solid #374558;
    }
    body.dark-only .b-r-light {
        border-right: 1px solid #374558 !important;
    }
    body.dark-only .history-details .media {
        border-top: 1px solid #374558;
    }
    body.dark-only .modal-header {
        border-bottom: 1px solid #374558;
    }
    body.dark-only #right-history {
        background-color: #262932;
        box-shadow: 0 0 9px #1d1e26;
    }
    body.dark-only #right-history h6 span a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .ProfileCard:hover {
        color: rgba(255, 255, 255, 0.6);
        background-color: #262932;
    }
    body.dark-only .translate_wrapper.active .more_lang:before {
        border-bottom: 7px solid #262932;
    }
    body.dark-only .translate_wrapper.active .more_lang .lang {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .toggle-sidebar svg {
        stroke: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .sidebar-main-title p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active svg {
        stroke: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-only
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
        background: #262932;
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
        background: #262932;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active,
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
        background: #262932;
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
        background: #262932;
    }
    body.dark-only
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content
        li
        a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
    body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
        background: #262932;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
        background-color: #262932;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-only
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper.material-type .page-header .header-wrapper {
        border-radius: 15px 15px 0 0;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
        border: 1px solid #1d1e26;
        background: #1d1e26;
        color: #fff;
    }
    body.dark-only .page-wrapper.material-icon .page-header .header-logo-wrapper {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.material-icon .page-body-wrapper .page-body {
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-header .header-wrapper .header-logo-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-body-wrapper {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper.advance-layout .page-body-wrapper .page-body {
        background: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .search-full {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .search-full input {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    body.dark-only .left-header .mega-menu-container {
        background-color: #262932;
        border-top: 1px solid #262932;
    }
    body.dark-only .left-header .mega-menu-container .mega-box + .mega-box {
        border-left: 1px solid #374558;
    }
    body.dark-only .left-header .mega-menu div > div a {
        color: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .left-header .level-menu .header-level-menu {
        background-color: #262932;
    }
    body.dark-only .left-header .level-menu .header-level-menu > li .header-level-sub-menu {
        background-color: #262932;
    }
    body.dark-only .left-header .level-menu .header-level-menu > li a {
        color: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .left-header .level-menu .header-level-menu > li svg {
        stroke: rgba(255, 255, 255, 0.7);
    }
    body.dark-only .kanban-board-header {
        background-color: #1d1e26 !important;
        border-bottom: 1px solid #262932 !important;
    }
    body.dark-only .kanban-board .kanban-drag {
        background: #1d1e26 !important;
    }
    body.dark-only .kanban-container .kanban-item .kanban-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .project-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-content .form-inline {
        border: 1px solid #1d1e26;
    }
    body.dark-only .file-content .files .file-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-content .files .file-box .file-top {
        background-color: #262932;
        border: 1px solid rgba(115, 102, 255, 0.15);
    }
    body.dark-only .file-content .folder .folder-box {
        border: 1px solid rgba(115, 102, 255, 0.15);
        background-color: #1d1e26;
    }
    body.dark-only .file-sidebar .pricing-plan {
        border: 1px solid rgba(115, 102, 255, 0.15) !important;
    }
    body.dark-only .file-sidebar .btn-light {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26 !important;
        border: 1px solid rgba(115, 102, 255, 0.15) !important;
    }
    body.dark-only #lnb {
        border-right: 1px solid #374558;
    }
    body.dark-only .lnb-new-schedule,
    body.dark-only .lnb-calendars > div {
        border-bottom: 1px solid #374558;
    }
    body.dark-only #menu .sidebar-list .btn-default {
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .tui-full-calendar-timegrid-hour {
        background-color: #262932;
        color: #fff !important;
    }
    body.dark-only .tui-full-calendar-timegrid-gridline {
        border-bottom: 1px solid #374558 !important;
    }
    body.dark-only .tui-full-calendar-time-date,
    body.dark-only .tui-full-calendar-weekday-grid-line,
    body.dark-only .tui-full-calendar-left,
    body.dark-only .tui-full-calendar-timegrid-timezone {
        border-right-color: #374558 !important;
    }
    body.dark-only .tui-full-calendar-popup {
        color: #2b2b2b;
    }
    body.dark-only #menu .btn-default {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only #menu .btn-default:hover {
        color: #2b2b2b;
    }
    body.dark-only #menu .dropdown-menu {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .tui-full-calendar-dayname-container,
    body.dark-only .tui-full-calendar-splitter {
        border-top-color: #374558 !important;
        border-bottom-color: #374558 !important;
    }
    body.dark-only span.tui-full-calendar-dayname-date-area {
        color: #fff !important;
    }
    body.dark-only .tui-full-calendar-layout {
        background-color: #262932 !important;
    }
    body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span {
        background-color: var(--theme-deafult);
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .right-side-chat .media-body .message-main span.loader-span .dot {
        background: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .blockquote {
        border-left: 4px solid #374558;
    }
    body.dark-only .figure.text-end blockquote {
        border-right-color: #374558;
    }
    body.dark-only .contacts-tabs .nav-pills {
        border-right: 1px solid #374558;
    }
    body.dark-only .contacts-tabs .nav-pills .nav-link + .nav-link {
        border-top: 1px solid #374558;
    }
    body.dark-only .list-persons .profile-mail .media .media-body ul li + li {
        border-left: 2px solid #374558;
    }
    body.dark-only .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
        background-color: #262932;
    }
    body.dark-only .apexcharts-gridline {
        stroke: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip-title,
    body.dark-only .apexcharts-tooltip-series-group,
    body.dark-only .apexcharts-tooltip.light,
    body.dark-only .apexcharts-menu-item {
        color: #000;
    }
    body.dark-only .small-donut ~ svg path:nth-child(even),
    body.dark-only .small-donut svg path:nth-child(even),
    body.dark-only .donut-peity2 ~ svg path:nth-child(even),
    body.dark-only .donut-peity2 svg path:nth-child(even),
    body.dark-only .pie-peity ~ svg path:nth-child(even),
    body.dark-only .pie-peity svg path:nth-child(even) {
        fill: #1d1e26;
    }
    body.dark-only .earning-card.card .card-body .left_side_earning p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .earning-content {
        border-right: 1px solid #374558;
    }
    body.dark-only .earning-card.card .card-body .inner-top-left ul li,
    body.dark-only .earning-card.card .card-body .inner-top-right ul li {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .inner-top-left ul li.active,
    body.dark-only .earning-card.card .card-body .inner-top-right ul li.active {
        color: var(--theme-deafult);
    }
    body.dark-only .earning-card.card .card-body .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .earning-card.card .card-body .border-top {
        border-top: 1px solid #374558 !important;
    }
    body.dark-only .chart_data_left .card-body .chart-main .media,
    body.dark-only .chart_data_right .card-body .chart-main .media {
        border-right: 1px solid #374558;
    }
    body.dark-only .chart_data_left .card-body .chart-main .media.border-none,
    body.dark-only .chart_data_right .card-body .chart-main .media.border-none {
        border-right: none;
    }
    body.dark-only .chart_data_left .card-body .media .right-chart-content span,
    body.dark-only .chart_data_right .card-body .media .right-chart-content span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .chart_data_right .card-body .knob-block input {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .third-news-update .news-update,
    body.dark-only .news .news-update,
    body.dark-only .appointment-sec .news-update {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .third-news-update .news-update span,
    body.dark-only .news .news-update span,
    body.dark-only .appointment-sec .news-update span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .third-news-update .news-update h6,
    body.dark-only .news .news-update h6,
    body.dark-only .appointment-sec .news-update h6 {
        margin-bottom: 2px;
        line-height: 24px;
    }
    body.dark-only .third-news-update .appointment .radar-chart .apexcharts-datalabel,
    body.dark-only .news .appointment .radar-chart .apexcharts-datalabel,
    body.dark-only .appointment-sec .appointment .radar-chart .apexcharts-datalabel {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control {
        background-color: #262932 !important;
        border: none !important;
        border-left: 1px solid var(--theme-deafult) !important;
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .chat-default .card-body.chat-box .input-group .form-control::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .appointment-table table tr td.img-content-box .font-roboto {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .notification .card .media .media-body p span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .notification .card .media .media-body span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .datepicker {
        background-color: #323846;
    }
    body.dark-only .datepicker .datepicker--cell.-current- {
        color: #03565a;
    }
    body.dark-only .cal-date-widget .datepicker {
        background-color: #323846;
    }
    body.dark-only .cal-date-widget .datepicker .datepicker--cell-day {
        color: #fff;
    }
    body.dark-only .cal-date-widget .datepicker .datepicker--cell.-current- {
        color: #03565a;
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker {
        background: #262932;
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--cell-day.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--day-name.-selected-,
    body.dark-only .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-selected- {
        color: #fff;
    }
    body.dark-only .dash-chart .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .dash-chart .media .media-body p .font-roboto {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .our-product table tr td p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .activity-timeline .media .activity-line {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .activity-timeline .media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .total-users .goal-status ul:last-child li {
        border-top-color: #374558;
    }
    body.dark-only .best-seller-table table tbody tr td .label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .best-seller-table table tbody tr td p {
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .best-seller-table table tbody tr:hover td {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .best-seller-table table tbody tr:hover td span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .best-seller-table table tbody tr:hover td .label,
    body.dark-only .best-seller-table table tbody tr:hover td p {
        color: var(--theme-deafult) !important;
    }
    body.dark-only [class*="activity-dot-"] {
        background-color: #262932;
    }
    body.dark-only .page-header .header-wrapper .nav-right .chat-dropdown li:last-child,
    body.dark-only .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
        border-top: 1px solid #374558 !important;
    }
    body.dark-only .alert-primary {
        background-color: rgba(115, 102, 255, 0.8) !important;
        border-color: rgba(115, 102, 255, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-primary .progress {
        height: 5px;
        background-color: #6e61ff;
        border-radius: 0;
    }
    body.dark-only .alert-primary .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-primary.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-primary.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-primary.outline .btn-close,
    body.dark-only .alert-primary.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-secondary {
        background-color: rgba(247, 49, 100, 0.8) !important;
        border-color: rgba(247, 49, 100, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-secondary .progress {
        height: 5px;
        background-color: #f72c60;
        border-radius: 0;
    }
    body.dark-only .alert-secondary .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-secondary.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-secondary.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-secondary.outline .btn-close,
    body.dark-only .alert-secondary.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-success {
        background-color: rgba(81, 187, 37, 0.8) !important;
        border-color: rgba(81, 187, 37, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-success .progress {
        height: 5px;
        background-color: #4fb724;
        border-radius: 0;
    }
    body.dark-only .alert-success .progress-bar {
        background-color: #ebfae5;
    }
    body.dark-only .alert-success.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-success.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-success.outline .btn-close,
    body.dark-only .alert-success.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-danger {
        background-color: rgba(220, 53, 69, 0.8) !important;
        border-color: rgba(220, 53, 69, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-danger .progress {
        height: 5px;
        background-color: #db3141;
        border-radius: 0;
    }
    body.dark-only .alert-danger .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-danger.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-danger.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-danger.outline .btn-close,
    body.dark-only .alert-danger.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-warning {
        background-color: rgba(248, 214, 43, 0.8) !important;
        border-color: rgba(248, 214, 43, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-warning .progress {
        height: 5px;
        background-color: #f8d526;
        border-radius: 0;
    }
    body.dark-only .alert-warning .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-warning.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-warning.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-warning.outline .btn-close,
    body.dark-only .alert-warning.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-info {
        background-color: rgba(169, 39, 249, 0.8) !important;
        border-color: rgba(169, 39, 249, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-info .progress {
        height: 5px;
        background-color: #a722f9;
        border-radius: 0;
    }
    body.dark-only .alert-info .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-info.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-info.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-info.outline .btn-close,
    body.dark-only .alert-info.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-light {
        background-color: rgba(244, 244, 244, 0.8) !important;
        border-color: rgba(244, 244, 244, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-light .progress {
        height: 5px;
        background-color: #f1f1f1;
        border-radius: 0;
    }
    body.dark-only .alert-light .progress-bar {
        background-color: #fff;
    }
    body.dark-only .alert-light.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-light.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-light.outline .btn-close,
    body.dark-only .alert-light.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .alert-dark {
        background-color: rgba(44, 50, 63, 0.8) !important;
        border-color: rgba(44, 50, 63, 0.9) !important;
        color: #fff;
    }
    body.dark-only .alert-dark .progress {
        height: 5px;
        background-color: #2a303c;
        border-radius: 0;
    }
    body.dark-only .alert-dark .progress-bar {
        background-color: #a8b0c2;
    }
    body.dark-only .alert-dark.inverse {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    body.dark-only .alert-dark.inverse .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .alert-dark.outline .btn-close,
    body.dark-only .alert-dark.outline-2x .btn-close {
        filter: brightness(1) invert(1);
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close {
        filter: unset;
    }
    body.dark-only .dismiss-text .alert-dismissible .btn-close .bg-light {
        color: #2b2b2b !important;
    }
    body.dark-only .options > div {
        border: 1px solid #374558;
    }
    body.dark-only .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
        background-color: #1d1e26;
    }
    body.dark-only .pricing-simple {
        box-shadow: 1px 1px 2px 1px #1d1e26 !important;
    }
    body.dark-only .pricing-block {
        box-shadow: 0 0 10px 5px rgba(115, 102, 255, 0.05) !important;
    }
    body.dark-only .search-page ul.search-info li + li {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .browse .browse-articles h6 {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .job-search .job-description .theme-form .row div[class^="col-"] .input-group .datepicker-here {
        border: 1px solid #1d1e26;
    }
    body.dark-only .calendar-wrap .fc-unthemed td,
    body.dark-only .calendar-wrap .fc-unthemed th {
        border-color: #1d1e26;
    }
    body.dark-only .mega-inline .card,
    body.dark-only .mega-horizontal .card,
    body.dark-only .mega-vertical .card {
        box-shadow: 1px 1px 7px 0 rgba(55, 69, 88, 0.5) !important;
    }
    body.dark-only .mega-inline.plain-style .card,
    body.dark-only .mega-inline.border-style .card,
    body.dark-only .mega-inline.offer-style .card,
    body.dark-only .mega-horizontal.plain-style .card,
    body.dark-only .mega-horizontal.border-style .card,
    body.dark-only .mega-horizontal.offer-style .card,
    body.dark-only .mega-vertical.plain-style .card,
    body.dark-only .mega-vertical.border-style .card,
    body.dark-only .mega-vertical.offer-style .card {
        box-shadow: none !important;
    }
    body.dark-only .mega-inline.border-style .card,
    body.dark-only .mega-horizontal.border-style .card,
    body.dark-only .mega-vertical.border-style .card {
        border: 1px solid #374558 !important;
    }
    body.dark-only .mega-inline.offer-style .card,
    body.dark-only .mega-horizontal.offer-style .card,
    body.dark-only .mega-vertical.offer-style .card {
        border: 1px dashed #374558 !important;
    }
    body.dark-only .setting-dot .setting-bg {
        background-color: var(--theme-secondary);
    }
    body.dark-only .bookmark.pull-right {
        border: none;
    }
    body.dark-only .popover {
        background-color: #1d1e26;
    }
    body.dark-only .popover.bs-popover-bottom .popover-arrow:after {
        border-bottom-color: #1d1e26;
        border-right-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .popover.bs-popover-top .popover-arrow:after {
        border-right-color: rgba(0, 0, 0, 0);
        border-top-color: #1d1e26;
    }
    body.dark-only .popover.bs-popover-start .popover-arrow:after {
        border-left-color: #1d1e26;
    }
    body.dark-only .popover .popover-header {
        background-color: #262932;
    }
    body.dark-only .popover .popover-arrow:after {
        border-right-color: #1d1e26;
    }
    body.dark-only .popover .popover-body {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .popover code {
        background-color: unset;
    }
    body.dark-only .apex-chart-container .apexcharts-legend .apexcharts-legend-series span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-yaxis-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-xaxis-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-label {
        fill: #fff;
    }
    body.dark-only .apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-value {
        fill: #fff;
    }
    body.dark-only .Typeahead-menu {
        background-color: #1d1e26;
    }
    body.dark-only .ecommerce-widget {
        border: 1px solid #1d1e26;
    }
    body.dark-only .bookmark ul li .search-form .form-control-search input {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .bookmark ul li .search-form .form-control-search:before {
        background: #1d1e26;
    }
    body.dark-only .cart .qty-box .input-group .btn {
        background-color: #2e3648 !important;
        border-color: #374558 !important;
    }
    body.dark-only .checkout .checkout-details {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        padding: 40px;
    }
    body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
        border: 2px solid #262932;
    }
    body.dark-only .order-box .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .order-box .qty {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .order-box .qty li {
        color: #bfc2c6;
    }
    body.dark-only .order-box .qty li span {
        color: #bfc2c6;
    }
    body.dark-only .order-box .sub-total li {
        color: #bfc2c6;
    }
    body.dark-only .order-box .total li {
        color: #bfc2c6;
    }
    body.dark-only .shopping-checkout-option .checkbox_animated:after {
        border: 2px solid #262932;
    }
    body.dark-only .animate-chk .radio_animated:after {
        border: 2px solid #1d1e26;
    }
    body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(41, 50, 64, 0.52);
    }
    body.dark-only .product-box .product-img .product-hover ul li .btn {
        color: #98a6ad;
    }
    body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
        color: #fff;
    }
    body.dark-only .radio_animated:after,
    body.dark-only .checkbox_animated:after {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .slider-product {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .square-product-setting .icon-grid {
        background-color: #262932;
    }
    body.dark-only .square-product-setting .icon-grid svg {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .active-order-table table thead tr th,
    body.dark-only .market-table table thead tr th {
        border-top: none !important;
    }
    body.dark-only .active-order-table table tbody tr td,
    body.dark-only .market-table table tbody tr td {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .active-order-table table tbody tr:last-child td,
    body.dark-only .market-table table tbody tr:last-child td {
        border-bottom: none;
    }
    body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .flot-chart-container .legend .table tbody {
        background-color: #262932;
        color: #fff;
    }
    body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
        padding-left: 5px;
    }
    body.dark-only .google-visualization-tooltip text {
        fill: rgba(0, 0, 0, 0.7) !important;
    }
    body.dark-only .nav-dark .nav-link,
    body.dark-only .nav-pills.nav-dark .nav-link {
        color: #fff;
    }
    body.dark-only .loader-wrapper {
        background-color: #1d1e26;
    }
    body.dark-only .loader-wrapper .loader {
        background-color: #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-header .translate_wrapper.active .more_lang {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .translate_wrapper.active .lang {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header form {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper li i {
        color: #f4f4f4;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li svg {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right.right-header > ul > li .profile-media .media-body p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .media .media-body .txt-dark {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div {
        background-color: #1d1e26;
        box-shadow: 0 0 14px #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div li {
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .onhover-show-div:after {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div li span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .nav-menus li .notification-dropdown.onhover-show-div .bg-light {
        background-color: #282e38 !important;
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg path,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg line,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a svg circle {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg line,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg path,
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a:hover svg circle {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .media .media-body .txt-dark {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div {
        background-color: #1d1e26;
        box-shadow: 0 0 2px 2px #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .onhover-show-div:after {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li {
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right > ul > li .notification-dropdown.onhover-show-div li span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .nav-right .chat-dropdown li {
        color: #fff;
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form input::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-dark {
        display: none;
    }
    body.dark-only .page-wrapper .page-header .header-logo-wrapper .logo-wrapper a .image-light {
        display: block;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .header-small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .statistics p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .feather-main .professor-block p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table .professor-block p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .logs-element span + span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-block .progress-title span + span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .ecommerce-icons div span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .new-users .media .media-body p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .recent-notification .media .media-body p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .media .media-body span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .progress-media .progress-change span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr td {
        border-top: none !important;
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .topper-lists table tbody tr:last-child td {
        border-bottom: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .notifiaction-media .media .media-body .circle-left {
        border: 5px solid #334154;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .upcoming-event .upcoming-innner:last-child {
        border-bottom: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .professor-table table tbody tr td {
        border-top: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .number-widgets .media .media-body h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.gradient-line-1:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.small-line:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .gradient-round.medium-line:after {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .activity .media .media-body h6 span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card.custom-card .card-header {
        border-bottom: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .custom-card .card-header img {
        background-color: #000;
        opacity: 0.8;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .row h3 small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb li {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-title .breadcrumb-item.active {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card:not(.email-body) {
        border: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) {
        background-color: #262932;
        box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block #bar-chart2 svg > g text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-block .word-tree svg > g > text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header > span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h3,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h4,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-header .card-header-right i.fa-cog {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-dark a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.outline-2x,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .alert-light.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #animation-box .animate-widget p {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .grid-item img {
        background-color: #262932;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .line {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) thead,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tbody,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tfoot,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) th {
        border-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead th {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table thead .border-bottom-primary th {
        border-bottom: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-primary td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-secondary td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-success td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-info td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-warning td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-light td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .table-danger td {
        color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-primary,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-secondary,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-success,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-info,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-warning,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-danger {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .bg-light {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table .thead-light th {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table tbody .border-bottom-primary td {
        border-bottom: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*="bg-"] th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table[class*="bg-"] td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd) {
        --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-striped tbody tr:nth-of-type(odd):hover td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-double,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dotted,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-dashed {
        border-left-color: #374558;
        border-right-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered {
        border-color: #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-bordered th {
        border-color: #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-inverse tbody tr:nth-of-type(odd):hover td {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-border-vertical td {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling thead td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table-styling tbody td {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .card-footer {
        background-color: #262932;
        border-top: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch .switch-state {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .switch input:checked + .switch-state {
        background-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bg-white {
        background-color: #fff !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-l-light {
        border-left: 1px solid #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-grid {
        stroke: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ct-label {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) hr {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .text-muted {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .calender-widget .cal-date {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .contact-form .theme-form {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .contact-form .theme-form .form-icon {
        background-color: #262932;
        border: 1px solid #374558;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-dark,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light-2x {
        color: #fff !important;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .btn-outline-light:hover {
        color: #000 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-right {
        border-right: 1px solid #1d1e26 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder .flot-text {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-placeholder svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart svg g rect:first-child {
        fill: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#gantt_chart rect:nth-child(6) {
        fill: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#line-chart rect,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart1 rect,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow#column-chart2 rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > rect {
        fill: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > g > g text {
        fill: #b1b1b2;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-overflow svg > g > text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .radial-bar:after {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .bar-chart-widget .bottom-content .num .color-bottom {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .b-r-light {
        border-right: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-labels .ct-label,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-labels .ct-label {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .live-products .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .turnover .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .monthly .ct-grid,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container .uses .ct-grid {
        stroke: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #browser-uses-chart svg text,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .chart-container #website-visiter-chart svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details h4 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .status-details span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .clipboaard-container .form-control {
        background-color: #1d1e26;
        color: #98a6ad;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .img-thumbnail {
        background-color: #1d1e26;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper button {
        color: #000;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper.no-footer .dataTables_scrollBody {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-danger,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-success,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .btn-primary {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_length,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_info,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_processing,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_filter input[type="search"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr:hover > .sorting_1 {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd {
        background-color: #1f232b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
        background-color: #22262f;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable thead td {
        border-bottom: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody tr {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
        border: 1px solid #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .table-striped tbody tr:nth-of-type(odd) {
        --bs-table-accent-bg: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button:active {
        border-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper .dataTables_paginate .paginate_button.disabled :active {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.row-border tbody td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody td {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr.even:hover > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
        background-color: #191e27;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.hover tbody tr:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr:hover {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td {
        border-top: 1px solid #1d1e26;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border th:first-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.cell-border td:first-child {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.order-column tbody tr > .sorting_3,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_2,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .dataTables_wrapper table.dataTable.display tbody tr > .sorting_3 {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #example-style-3_wrapper #example-style-3 tfoot {
        border-top: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.disabled .page-link {
        background-color: #262932;
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-link {
        color: rgba(255, 255, 255, 0.6);
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item:hover .page-link {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .page-item.active .page-link {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .icon {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget .total-num span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .ecommerce-widget span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .flot-chart-container-small {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td span,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table #API-2_wrapper #API-2 tbody td p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .product-table h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-item .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link.active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .border-tab.nav-tabs .nav-link:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-1to10 .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-movie .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-pill .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-reversed .br-widget a.br-selected,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-horizontal .br-widget a.br-selected {
        background-color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a {
        border: 2px solid #1d1e26;
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-active,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-bars-square .br-widget a.br-selected {
        border: 2px solid var(--theme-deafult);
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-selected:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars .br-widget a.br-active:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .br-theme-fontawesome-stars-o .br-widget a.br-active:after {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .scroll-demo {
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form input {
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .search-form .form-group:before {
        background: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-content::before {
        border-left: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        border-right-color: #1d1e26;
        border-left: rgba(0, 0, 0, 0);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .breadcrumb.bg-white {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .user-status table th {
        border-top: none !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris-daily svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #donut-color-chart-morris svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #browser-uses-chart svg,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) #website-visiter-chart svg {
        opacity: 0.5;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-header-1,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-2-header {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .show > .nav-link {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-link.active {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .nav-primary .nav-pills.nav-primary .nav-link.active {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .drag-box fieldset {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder .help-block {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body #viewhtml .render {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-builder-column .drag-bx {
        border: 1px dotted #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .wizard-4 ul.anchor li a.disabled {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description a > div {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .gallery-with-description p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-header {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell {
        background: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-header-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row > .jsgrid-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-header-cell input:focus,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row > .jsgrid-cell input:focus {
        outline: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-header-row select,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-filter-row select {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-row > .jsgrid-cell {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-alt-row > .jsgrid-cell {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-cell,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-grid-body {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-selected-row > .jsgrid-cell {
        background-color: #1c212b;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager [class*="jsgrid-pager"] {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid .jsgrid-pager .jsgrid-pager-page a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .jsgrid-pager-current-page {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 700;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .sub-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon .separator {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according li .text-muted {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-icon.default-according.style-1 li button:hover {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .nav-list .nav-list-disc li:hover a {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div {
        background-color: #1d1e26;
        box-shadow: 0 0 14px 0 #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li p {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg path,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg line,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polyline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg polygon,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a svg rect {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg polygon,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .navs-dropdown .onhover-show-div .navs-icon li a:hover svg rect {
        color: var(--theme-deafult) !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .onhover-dropdown:hover .onhover-show-div:before {
        border-bottom: 7px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .btn-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .default-according .card .card-body {
        border: 1px solid #374558;
        border-top: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .border {
        border: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-date {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li {
        color: #98a6ad;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:first-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .blog-social li:last-child,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .blog-social li:last-child {
        border-right: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details .single-blog-content-top p,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .blog-box .blog-details-main .single-blog-content-top p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media h6 span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .media img {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box .comment-social li:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .comment-box hr {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover {
        --bs-table-accent-bg: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover td,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .table-hover > tbody > tr:hover th {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li {
        border: 1px dotted #374558;
        color: rgba(255, 255, 255, 0.6);
        display: inline-block;
        padding: 10px;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li:hover {
        background: #000;
        box-shadow: 0 0 3px #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body ul.the-icons li em {
        display: none;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .box {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap .button-preview h2 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .button-builder-wrap pre.well {
        background-color: #262932 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li + li {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity > li h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .crm-activity ul.dates li {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris svg text,
    body.dark-only .page-wrapper .page-body-wrapper .page-body #donut-color-chart-morris-daily svg text {
        fill: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-profile img {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .profile-details h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-social li a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .custom-card .card-footer > div + div {
        border-left: 1px solid #323846;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .checkbox label::before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .radio label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0);
        border-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content a:hover {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-header {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-basic .dropdown .dropdown-content .dropdown-divider {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a:hover,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropup-basic .dropup .dropup-content a.active {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone.dz-clickable .dz-message span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropzone .dz-preview .dz-details {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body {
        column-rule: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body h4 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .browser-widget .media-body span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .media img {
        border: 2px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar .main-menu > li a:hover {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-app-sidebar ul li a > .title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .row .col-xl-4 {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-right-aside .email-body .inbox .media.active {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body h6 small span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .media-body p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-top {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap p {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .email-wrap .email-content .email-top .user-emailid:after {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu {
        background-color: #1d1e26;
        border: 1px solid #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a {
        color: rgba(255, 255, 255, 0.6);
        background: rgba(0, 0, 0, 0);
        border-top-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .dropdown-menu a:hover {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div i {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .icon-lists div:hover {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .media-body > span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins .media .details {
        border-left: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after {
        background-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .redial-social-widget i {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .social-widget-card span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .b-b-light {
        border-bottom: 1px solid #374558 !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .b-r-dark {
        border-right: 1px solid #fff !important;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .testimonial span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-showcase span {
        background-color: #262932;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .grid-align .row {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-html-output {
        background-color: #1d1e26;
        color: #98a6ad;
        border: 1px solid #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-grid {
        background-color: #262932;
        color: #98a6ad !important;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-mainControls .ge-addRowGroup .ge-row-icon .column {
        border-left: 3px solid #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-code,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .btn-screen {
        color: #98a6ad;
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .row {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .column {
        border: 1px solid #1d1e26;
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details input {
        background-color: #1d1e26;
        border-color: #1d1e26;
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-canvas.ge-editing .ge-tools-drawer .ge-details .btn-group a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .page-builder .ge-content-type-ckeditor {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .search-page .info-block {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-primary h5,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .card-absolute .bg-secondary h5 {
        color: #fff;
    }
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.timeline-line-1:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.small-line:after,
    body.dark-only .page-wrapper .page-body-wrapper .page-body .timeline-small .media .timeline-round.medium-line:after {
        background-color: #374558;
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer {
        border-top: 1px solid #1d1e26;
        background-color: #262932;
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .custom-select,
    body.dark-only .page-wrapper .page-body-wrapper .custom-file-label {
        background: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .page-body-wrapper .footer-fix {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .note textarea {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .dt-button-info {
        background-color: #262932;
        border: 1px solid #98a6ad;
    }
    body.dark-only .page-wrapper .dt-button-info h2 {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .chat-box .about .name {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .chat-menu {
        border-left: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .icon-wrapper {
        background-color: #262932;
        box-shadow: 1px 1px 3px 1px #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .image .avatar img {
        border: 5px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .social-media a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow span {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-menu .user-profile .follow .follow-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .status {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .status p {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-header img {
        box-shadow: 1px 1px 4px 1px #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .my-message {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .message {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message {
        background-color: #262932;
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-right-aside .chat .chat-message .input-group .form-control {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li a {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper pre {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .scorlled {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .input-group-air {
        box-shadow: 0 3px 20px 0 #1d1e26;
    }
    body.dark-only .page-wrapper .input-group-text {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .input-group-text i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .input-group-solid .input-group-text,
    body.dark-only .page-wrapper .input-group-solid .form-control {
        background: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .semilight-bg-color,
    body.dark-only .page-wrapper .header-bg-color {
        background-color: #fff;
    }
    body.dark-only .page-wrapper .list-group-item {
        background-color: #262932;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .list-group-item + .list-group-item {
        border-top-width: 0;
    }
    body.dark-only .page-wrapper .list-group-item.active {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult);
        color: #fff;
    }
    body.dark-only .page-wrapper .list-group-item:hover,
    body.dark-only .page-wrapper .list-group-item:focus {
        z-index: 1;
        text-decoration: none;
    }
    body.dark-only .page-wrapper .list-group-item-action:hover:not(.active),
    body.dark-only .page-wrapper .list-group-item-action:focus {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .list-group-flush .list-group-item {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
    }
    body.dark-only .page-wrapper .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
    }
    body.dark-only .page-wrapper .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
    }
    body.dark-only .page-wrapper .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
    }
    body.dark-only .page-wrapper .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
    }
    body.dark-only .page-wrapper .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
    }
    body.dark-only .page-wrapper .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
    }
    body.dark-only .page-wrapper .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
    }
    body.dark-only .page-wrapper .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
    }
    body.dark-only .page-wrapper .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group input {
        color: rgba(255, 255, 255, 0.6);
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .form-group label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .auth-bg .authentication-box h3,
    body.dark-only .page-wrapper .auth-bg .authentication-box h4,
    body.dark-only .page-wrapper .auth-bg .authentication-box h6 {
        color: #fff;
    }
    body.dark-only .page-wrapper .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h4,
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h3 {
        color: #fff;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type="text"],
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .form-group input[type="password"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .auth-bg-video .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .error-wrapper .maintenance-icons li:nth-child(3) i {
        color: #1d1e26;
    }
    body.dark-only .page-wrapper .modal-content {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .modal-content .modal-header {
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .modal-content .modal-header .close {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .modal-content .modal-footer {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .countdown .title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .comingsoon .comingsoon-inner .coming-soon-bottom-link a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .theme-form .login-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .theme-form .login-divider:before {
        background: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .reset-password-link {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group label {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .form-group .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .reset-password-box .theme-form .opt-box {
        background-color: #1d1e26;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h4 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h6 {
        color: #98a6ad;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box h3 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type="text"],
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .form-group input[type="password"] {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .page-wrapper .authentication-main .auth-innerright .authentication-box .card .theme-form .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .vertical-menu-main {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .mega-menu .title {
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .page-wrapper .mega-menu .list-unstyled div a:hover {
        color: var(--theme-deafult);
    }
    body.dark-only .page-wrapper .default-according .card {
        background-color: #262932;
    }
    body.dark-only .page-wrapper .default-according .card .btn-link {
        background-color: #262932;
        border: 1px solid #374558;
        border-bottom: none;
        color: #fff;
    }
    body.dark-only .page-wrapper .default-according .card .text-muted {
        color: #98a6ad !important;
    }
    body.dark-only .page-wrapper .default-according .bg-primary .btn-link {
        background-color: var(--theme-deafult);
        border: 1px solid var(--theme-deafult);
    }
    body.dark-only .page-wrapper .default-according .bg-secondary .btn-link {
        background-color: var(--theme-secondary);
        border: 1px solid var(--theme-secondary);
    }
    body.dark-only .page-wrapper .collapse .card-body {
        background-color: #262932;
    }
    @media screen and (max-width: 1660px) {
        body.dark-only .page-wrapper .chat-box .chat-history .call-icons ul li {
            border: 1px solid #1d1e26;
        }
        body.dark-only .page-wrapper .chat-box .chat-history .total-time h2 {
            color: rgba(255, 255, 255, 0.6);
        }
        body.dark-only .page-wrapper .caller-img img {
            opacity: 0.7;
        }
        body.dark-only .page-wrapper .chat-box .chat-history .call-content > div {
            background-color: rgba(0, 0, 0, 0.75);
            background-blend-mode: overlay;
        }
    }
    @media only screen and (max-width: 1199.98px) {
        body.dark-only .page-wrapper .left-header .mega-menu-container {
            border-left: 1px solid #374558;
        }
        body.dark-only .page-wrapper .left-header .mega-menu-container .mega-box + .mega-box {
            border-left: none;
        }
    }
    @media only screen and (max-width: 1199px) {
        body.dark-only .page-wrapper .mobile-title.d-none {
            border-bottom: 1px solid #374558;
        }
        body.dark-only .page-wrapper .chat-menu {
            border-top: 1px solid #1d1e26;
            background-color: #262932;
        }
        body.dark-only .page-wrapper .error-wrapper .maintenance-icons li i {
            color: #2c323f;
        }
    }
    @media only screen and (max-width: 575.98px) {
        body.dark-only .page-wrapper .user-profile .hovercard .info .user-designation {
            border-top: 1px solid #1d1e26;
            border-bottom: 1px solid #1d1e26;
        }
        body.dark-only .page-wrapper .widget-joins .border-after-xs:after {
            background-color: #1d1e26;
        }
        body.dark-only .page-wrapper .page-header .header-wrapper .left-header ul li .search-form .form-control-plaintext {
            background-color: #1d1e26;
        }
        body.dark-only .page-wrapper .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar,
        body.dark-only .page-wrapper .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
            border-right: 1px solid #374558;
        }
    }
    body.dark-only .lg-backdrop {
        background-color: #1d1e26;
    }
    body.dark-only .lg-outer .lg-thumb-outer {
        background-color: #262932;
    }
    body.dark-only .drag {
        background-color: #262932;
    }
    body.dark-only .drag form {
        background: #262932;
        box-shadow: 0 0 30px #1d1e26;
        border: 1px dotted #1d1e26;
    }
    body.dark-only .drag form input {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .drag form .help-block {
        text-transform: capitalize;
        color: #98a6ad;
    }
    body.dark-only .draggable input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .draggable p {
        color: #98a6ad;
    }
    body.dark-only .draggable select {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .draggable .radio label::before,
    body.dark-only .draggable .checkbox label::before {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .select2-container--default .select2-selection--multiple,
    body.dark-only .select2-container--default .select2-selection--single {
        background-color: #1d1e26;
        border: 1px solid #1d1e26 !important;
    }
    body.dark-only .select2-container--default .select2-search--inline .select2-search__field {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-search--dropdown .select2-search__field {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: #262932 !important;
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .select2-dropdown {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only .select2-drpdwn .form-control-primary {
        border-color: var(--theme-deafult) !important;
        color: var(--theme-deafult) !important;
    }
    body.dark-only .select2-drpdwn .form-control-secondary {
        border-color: var(--theme-secondary) !important;
        color: var(--theme-secondary) !important;
    }
    body.dark-only .select2-drpdwn .form-control-success {
        border-color: #51bb25 !important;
        color: #51bb25 !important;
    }
    body.dark-only .select2-drpdwn .form-control-info {
        border-color: #a927f9 !important;
        color: #a927f9 !important;
    }
    body.dark-only .select2-drpdwn .form-control-warning {
        border-color: #f8d62b !important;
        color: #f8d62b !important;
    }
    body.dark-only .select2-drpdwn .form-control-danger {
        border-color: #dc3545 !important;
        color: #dc3545 !important;
    }
    body.dark-only .select2-drpdwn .form-control-inverse {
        border-color: #fff !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-primary-fill {
        background-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-secondary-fill {
        background-color: var(--theme-secondary) !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-success-fill {
        background-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-info-fill {
        background-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-warning-fill {
        background-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-danger-fill {
        background-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .select2-drpdwn .form-control-inverse-fill {
        background-color: #fff !important;
        color: #000 !important;
    }
    body.dark-only .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: #1d1e26;
    }
    body.dark-only .theme-form .form-group select.form-control:not([size]):not([multiple]) {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26;
    }
    body.dark-only .theme-form .form-group input[type="text"],
    body.dark-only .theme-form .form-group input[type="email"],
    body.dark-only .theme-form .form-group input[type="search"],
    body.dark-only .theme-form .form-group input[type="password"],
    body.dark-only .theme-form .form-group input[type="number"],
    body.dark-only .theme-form .form-group input[type="tel"],
    body.dark-only .theme-form .form-group input[type="date"],
    body.dark-only .theme-form .form-group input[type="datetime-local"],
    body.dark-only .theme-form .form-group input[type="time"],
    body.dark-only .theme-form .form-group input[type="datetime-local"],
    body.dark-only .theme-form .form-group input[type="month"],
    body.dark-only .theme-form .form-group input[type="week"],
    body.dark-only .theme-form .form-group input[type="url"],
    body.dark-only .theme-form .form-group input[type="file"],
    body.dark-only .theme-form .form-group select {
        border-color: #1d1e26;
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .theme-form .form-group input[type="text"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="email"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="search"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="password"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="number"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="date"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="time"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="month"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="week"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="url"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group input[type="file"]::-webkit-input-placeholder,
    body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
        color: #98a6ad;
    }
    body.dark-only .theme-form .form-group .form-control {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .theme-form .form-group textarea {
        border-color: #1d1e26;
    }
    body.dark-only .theme-form .form-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .theme-form .form-divider::before {
        background: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .CodeMirror {
        background: #262932;
        border: 1px solid #98a6ad;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .CodeMirror .CodeMirror-code pre {
        background: #262932;
    }
    body.dark-only .editor-toolbar {
        border-top: 1px solid #98a6ad;
        border-left: 1px solid #98a6ad;
        border-right: 1px solid #98a6ad;
    }
    body.dark-only .editor-toolbar a {
        color: rgba(255, 255, 255, 0.6) !important;
    }
    body.dark-only .editor-toolbar a:hover,
    body.dark-only .editor-toolbar a.active {
        background: #1d1e26;
    }
    body.dark-only .editor-toolbar i.separator {
        border-left: 1px solid #98a6ad;
        border-right: 1px solid #98a6ad;
    }
    body.dark-only .editor-toolbar.fullscreen::before {
        background: linear-gradient(to right, #1d1e26 0, rgba(0, 0, 0, 0) 100%);
    }
    body.dark-only .editor-toolbar.fullscreen::after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, #1d1e26 100%);
    }
    body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
        background: #1d1e26;
    }
    body.dark-only .editor-preview {
        background-color: #262932;
    }
    body.dark-only .editor-toolbar.fullscreen,
    body.dark-only .editor-preview-side {
        background: #262932;
        border: 1px solid #98a6ad;
    }
    body.dark-only .u-step {
        background: #1d1e26;
    }
    body.dark-only .u-step.active,
    body.dark-only .u-step.current {
        background: var(--theme-deafult);
        color: #fff;
    }
    body.dark-only .u-step-title,
    body.dark-only .u-pearl-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .u-step-number {
        background-color: #262932;
    }
    body.dark-only .u-pearl:before {
        background-color: #1d1e26;
    }
    body.dark-only .u-pearl-number,
    body.dark-only .u-pearl-icon {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .u-pearl.disabled .u-pearl-icon,
    body.dark-only .u-pearl.disabled .u-pearl-number {
        background: #1d1e26;
        border: 2px solid #1d1e26;
    }
    body.dark-only .u-pearl.disabled:after {
        background-color: #334053;
    }
    body.dark-only .u-pearl.error:after {
        background-color: #1d1e26;
    }
    body.dark-only .note-editor.note-frame {
        border-color: #1d1e26;
    }
    body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .swal-modal {
        background-color: #262932;
    }
    body.dark-only .swal-modal .swal-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .swal-modal .swal-text {
        color: #98a6ad;
    }
    body.dark-only .swal-modal .swal-content__input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .nav-tabs {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .nav-tabs .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .nav-tabs .nav-link.active {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #374558 #374558 #262932;
    }
    body.dark-only .nav-tabs .nav-link:hover,
    body.dark-only .nav-tabs .nav-link:focus {
        border-color: #374558 #374558 #323846;
    }
    body.dark-only .nav-tabs .nav-item.show .nav-link {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border-color: #1d1e26 #1d1e26 #262932;
    }
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active,
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus,
    body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
        border-color: #262932 #1d1e26 #1d1e26;
    }
    body.dark-only .border-tab.nav-left .nav-link,
    body.dark-only .border-tab.nav-right .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .border-tab.nav-left .nav-link.active,
    body.dark-only .border-tab.nav-right .nav-link.active {
        color: var(--theme-deafult);
    }
    body.dark-only .border-tab.nav-left .show > .nav-link,
    body.dark-only .border-tab.nav-right .show > .nav-link {
        color: var(--theme-deafult);
    }
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active,
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus,
    body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
    }
    body.dark-only .border-tab.nav-left.nav-success .nav-link.active,
    body.dark-only .border-tab.nav-left.nav-success .nav-link:focus,
    body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
        border-left-color: #51bb25;
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
        color: #51bb25 !important;
        border-left-color: #51bb25;
    }
    body.dark-only .border-tab.nav-right.nav-info .nav-link.active,
    body.dark-only .border-tab.nav-right.nav-info .nav-link:focus,
    body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
        color: #a927f9 !important;
    }
    body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
        border-left-color: #a927f9;
        color: #a927f9 !important;
    }
    body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
        color: #a927f9 !important;
        border-left-color: #a927f9;
    }
    body.dark-only .border-tab.nav-secondary .nav-link.active,
    body.dark-only .border-tab.nav-secondary .nav-link:focus,
    body.dark-only .border-tab.nav-secondary .nav-link:hover {
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-secondary .nav-item.show {
        color: var(--theme-secondary) !important;
    }
    body.dark-only .border-tab.nav-success .nav-link.active,
    body.dark-only .border-tab.nav-success .nav-link:focus,
    body.dark-only .border-tab.nav-success .nav-link:hover {
        color: #51bb25 !important;
    }
    body.dark-only .border-tab.nav-success .nav-item.show {
        color: #51bb25 !important;
    }
    body.dark-only .dropdown-divider {
        border-top: 1px solid #1d1e26;
    }
    body.dark-only .icon-hover-bottom {
        background-color: #262932;
        box-shadow: 0px 0px 1px 1px #1d1e26;
    }
    body.dark-only .icon-hover-bottom .icon-title {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .icon-hover-bottom span {
        color: #98a6ad;
    }
    body.dark-only .icon-hover-bottom .form-group input {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #1d1e26;
    }
    body.dark-only .icon-hover-bottom .icon-first i {
        color: #98a6ad;
    }
    body.dark-only code {
        background-color: #1d1e26;
        border-radius: 2px;
    }
    body.dark-only #cd-timeline::before {
        background: #1d1e26;
    }
    body.dark-only .timeliny {
        border-top: 1px solid #1d1e26;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .timeliny::before {
        background-color: #fff;
    }
    body.dark-only .timeliny .timeliny-dot {
        background-color: #262932;
        border: 1px solid #fff;
    }
    body.dark-only .timeliny .timeliny-dot::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
        border: none;
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover {
        background-color: #fff;
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock:not(.inactive):not(.active) .timeliny-dot:hover::before {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
        background: #262932;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-label {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
        color: #fff;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover:not(.active) {
        background: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .task-label {
        color: var(--theme-deafult);
    }
    body.dark-only .todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover {
        border: 1px solid #51bb25 !important;
    }
    body.dark-only .todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea {
        background-color: #262932;
        border: 1px solid #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .ttl-info h6 {
        color: #98a6ad;
    }
    body.dark-only .user-profile .ttl-info span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .hovercard .info .title a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .hovercard .user-image .avatar img {
        border: 10px solid #262932;
    }
    body.dark-only .user-profile .hovercard .user-image .icon-wrapper {
        background-color: #262932;
    }
    body.dark-only .user-profile .hovercard .tabs-scoial {
        border-bottom: none !important;
    }
    body.dark-only .user-profile .follow .follow-num {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .profile-img-style .user-name {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .user-profile .profile-img-style p {
        color: #98a6ad;
    }
    body.dark-only .introjs-helperLayer {
        background-color: rgba(54, 64, 74, 0.9);
        border: rgba(255, 255, 255, 0.5);
    }
    body.dark-only .jstree-default .jstree-node,
    body.dark-only .jstree-default .jstree-icon {
        color: #455869 !important;
    }
    body.dark-only .jstree-default .jstree-anchor {
        color: #98a6ad;
    }
    body.dark-only .jstree-default .jstree-clicked {
        color: #fff;
        background-color: rgba(0, 0, 0, 0);
    }
    body.dark-only .jstree-default .jstree-hovered {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
    }
    body.dark-only span.twitter-typeahead .tt-menu {
        background-color: #1d1e26;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only span.twitter-typeahead .tt-suggestion {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only span.twitter-typeahead .tt-suggestion:hover,
    body.dark-only span.twitter-typeahead .tt-suggestion:focus {
        background-color: #262932;
    }
    body.dark-only .typography small {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .code-box-copy pre {
        background-color: #1d1e26;
    }
    body.dark-only .code-box-copy pre code {
        background-color: #1d1e26;
    }
    body.dark-only .code-box-copy pre[class*="language-"] {
        border: 1px solid #1d1e26;
    }
    body.dark-only .code-box-copy .code-box-copy__btn {
        background-color: #1d1e26;
        border: 1px solid #1d1e26;
        color: #fff;
    }
    body.dark-only .code-box-copy code[class*="language-"],
    body.dark-only .code-box-copy pre[class*="language-"] {
        text-shadow: 0px 1px #000;
    }
    body.dark-only .code-box-copy code[class*="language-"] ::selection,
    body.dark-only .code-box-copy pre[class*="language-"] ::selection {
        text-shadow: none;
        background: #262932;
    }
    body.dark-only table.fixedHeader-floating {
        background-color: #1d1e26;
    }
    body.dark-only .note textarea {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .dt-button-info {
        background-color: #262932;
        border: 1px solid #1d1e26;
    }
    body.dark-only .dt-button-info h2 {
        background-color: #262932;
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only pre {
        background-color: #1d1e26;
    }
    body.dark-only #keytable_wrapper .dataTables_paginate,
    body.dark-only #column-selector_wrapper .dataTables_paginate,
    body.dark-only #auto-fill_wrapper .dataTables_paginate,
    body.dark-only #custom-button_wrapper .dataTables_paginate,
    body.dark-only #class-button_wrapper .dataTables_paginate,
    body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
    body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
    body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
    body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
    body.dark-only #state-saving_wrapper .dataTables_paginate,
    body.dark-only #real-time_wrapper .dataTables_paginate,
    body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
    body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
    body.dark-only #export-button_wrapper .dataTables_paginate,
    body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
    body.dark-only #cust-json_wrapper .dataTables_paginate,
    body.dark-only #basic-key-table_wrapper .dataTables_paginate,
    body.dark-only #focus-cell_wrapper .dataTables_paginate,
    body.dark-only #responsive_wrapper .dataTables_paginate,
    body.dark-only #new-cons_wrapper .dataTables_paginate,
    body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
    body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
    body.dark-only #full-row_wrapper .dataTables_paginate,
    body.dark-only #rest-column_wrapper .dataTables_paginate {
        border: none;
    }
    body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
    body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
        background-color: #1d1e26;
    }
    body.dark-only .error-wrapper {
        background-color: rgba(54, 64, 74, 0.8);
    }
    body.dark-only .error-wrapper .sub-content {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .b-light {
        border: 1px solid #1d1e26 !important;
    }
    body.dark-only .modal-content {
        background-color: #262932;
    }
    body.dark-only .modal-content .modal-header {
        border-bottom: 1px solid #374558;
    }
    body.dark-only .modal-content .modal-header .close {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-only .modal-content .ui-front .form-control {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid #374558;
    }
    body.dark-only .stepwizard .stepwizard-row:before {
        background-color: #374558;
    }
    body.dark-only .modal .theme-close {
        background-color: #262932 !important;
        color: rgba(255, 255, 255, 0.2);
    }
    body.dark-only .token.atrule,
    body.dark-only .token.attr-value,
    body.dark-only .token.keyword {
        color: #a927f9;
    }
    body.dark-only .token.boolean,
    body.dark-only .token.constant,
    body.dark-only .token.deleted,
    body.dark-only .token.number,
    body.dark-only .token.property,
    body.dark-only .token.symbol,
    body.dark-only .token.tag {
        color: #dc3545;
    }
    body.dark-only .loader-box.card-loader {
        background-color: #262932;
    }
    body.dark-only .my-gallery.gallery-with-description img {
        border: 1px solid #1d1e26 !important;
        border-bottom: none !important;
    }
    body.dark-only .alert-primary.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-secondary.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-success.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-danger.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-warning.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-info.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-light.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-dark.inverse {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .alert-theme span + span + span {
        border-left: 5px solid var(--theme-deafult);
        background-color: #1d1e26;
        border-radius: 4px;
    }
    body.dark-only .alert-theme i {
        color: #fff;
        padding: 20px;
        margin-right: 20px !important;
    }
    body.dark-only .user-card .user-deatils h6 {
        color: #98a6ad;
    }
    body.dark-only .user-card .card-footer > div:first-child {
        border-right: 1px solid #1d1e26;
    }
    body.dark-only .user-card .card-footer .user-footer h6 {
        color: #98a6ad;
    }
    body.dark-only .user-card .card-footer .user-footer svg path,
    body.dark-only .user-card .card-footer .user-footer svg rect {
        color: #98a6ad;
    }
    body.dark-only .order-history table .qty-box button {
        background-color: #1d1e26 !important;
        border: none !important;
    }
    body.dark-only .order-history table .qty-box .input-group {
        border-color: #374558;
    }
    body.dark-only .order-history table thead tr th {
        background-color: #1d1e26;
    }
    body.dark-only .order-history table tr td,
    body.dark-only .order-history table tr th {
        border-top: none !important;
    }
    body.dark-only .order-history .title-orders {
        background-color: #1d1e26;
    }
    body.dark-only .navigation-option ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .navigation-option ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .product-box .product-details h6 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .product-box .product-details span {
        color: #98a6ad;
    }
    body.dark-only .product-box .product-img .product-hover ul li {
        background-color: #262932;
    }
    body.dark-only .product-box .product-img .product-hover ul li:hover {
        background-color: #000;
        color: #fff;
    }
    body.dark-only .browse .browse-articles ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .browse .browse-articles ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .radio-primary input[type="radio"] + label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .radio-primary input[type="radio"] + label::after {
        background-color: var(--theme-deafult);
    }
    body.dark-only .radio-primary input[type="radio"]:checked + label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .radio-primary input[type="radio"]:checked + label::after {
        background-color: var(--theme-deafult);
    }
    body.dark-only .radio-secondary input[type="radio"] + label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .radio-secondary input[type="radio"] + label::after {
        background-color: #f73164;
    }
    body.dark-only .radio-secondary input[type="radio"]:checked + label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .radio-secondary input[type="radio"]:checked + label::after {
        background-color: #f73164;
    }
    body.dark-only .radio-success input[type="radio"] + label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .radio-success input[type="radio"] + label::after {
        background-color: #51bb25;
    }
    body.dark-only .radio-success input[type="radio"]:checked + label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .radio-success input[type="radio"]:checked + label::after {
        background-color: #51bb25;
    }
    body.dark-only .radio-danger input[type="radio"] + label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .radio-danger input[type="radio"] + label::after {
        background-color: #dc3545;
    }
    body.dark-only .radio-danger input[type="radio"]:checked + label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .radio-danger input[type="radio"]:checked + label::after {
        background-color: #dc3545;
    }
    body.dark-only .radio-info input[type="radio"] + label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .radio-info input[type="radio"] + label::after {
        background-color: #a927f9;
    }
    body.dark-only .radio-info input[type="radio"]:checked + label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .radio-info input[type="radio"]:checked + label::after {
        background-color: #a927f9;
    }
    body.dark-only .radio-light input[type="radio"] + label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .radio-light input[type="radio"] + label::after {
        background-color: #f4f4f4;
    }
    body.dark-only .radio-light input[type="radio"]:checked + label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .radio-light input[type="radio"]:checked + label::after {
        background-color: #f4f4f4;
    }
    body.dark-only .radio-dark input[type="radio"] + label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .radio-dark input[type="radio"] + label::after {
        background-color: #2c323f;
    }
    body.dark-only .radio-dark input[type="radio"]:checked + label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .radio-dark input[type="radio"]:checked + label::after {
        background-color: #2c323f;
    }
    body.dark-only .radio-warning input[type="radio"] + label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .radio-warning input[type="radio"] + label::after {
        background-color: #f8d62b;
    }
    body.dark-only .radio-warning input[type="radio"]:checked + label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .radio-warning input[type="radio"]:checked + label::after {
        background-color: #f8d62b;
    }
    body.dark-only .checkbox-primary label::before {
        border-color: var(--theme-deafult) !important;
    }
    body.dark-only .checkbox-secondary label::before {
        border-color: #f73164 !important;
    }
    body.dark-only .checkbox-success label::before {
        border-color: #51bb25 !important;
    }
    body.dark-only .checkbox-danger label::before {
        border-color: #dc3545 !important;
    }
    body.dark-only .checkbox-info label::before {
        border-color: #a927f9 !important;
    }
    body.dark-only .checkbox-light label::before {
        border-color: #f4f4f4 !important;
    }
    body.dark-only .checkbox-dark label::before {
        border-color: #2c323f !important;
    }
    body.dark-only .checkbox-warning label::before {
        border-color: #f8d62b !important;
    }
    body.dark-only .checkbox-solid-primary label:before {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-primary input[type="checkbox"]:checked + label::before {
        background-color: var(--theme-deafult) !important;
        border-color: var(--theme-deafult) !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-primary input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary label:before {
        background-color: #f73164 !important;
        border-color: #f73164 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary input[type="checkbox"]:checked + label::before {
        background-color: #f73164 !important;
        border-color: #f73164 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-secondary input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success label:before {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success input[type="checkbox"]:checked + label::before {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-success input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger label:before {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger input[type="checkbox"]:checked + label::before {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-danger input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info label:before {
        background-color: #a927f9 !important;
        border-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::before {
        background-color: #a927f9 !important;
        border-color: #a927f9 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-info input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light label:before {
        background-color: #f4f4f4 !important;
        border-color: #f4f4f4 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light input[type="checkbox"]:checked + label::before {
        background-color: #f4f4f4 !important;
        border-color: #f4f4f4 !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-light input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark label:before {
        background-color: #2c323f !important;
        border-color: #2c323f !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::before {
        background-color: #2c323f !important;
        border-color: #2c323f !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-dark input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning label:before {
        background-color: #f8d62b !important;
        border-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning input[type="checkbox"]:checked + label::before {
        background-color: #f8d62b !important;
        border-color: #f8d62b !important;
        color: #fff !important;
    }
    body.dark-only .checkbox-solid-warning input[type="checkbox"]:checked + label::after {
        color: #fff !important;
    }
    body.dark-only .bootstrap-datetimepicker-widget ul li {
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid #1d1e26;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
    body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
        background-color: #262932;
    }
    body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
        background-color: #262932;
    }
    body.dark-only .btn-transparent {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after,
    body.dark-only #cal-basic .fc-toolbar .fc-right .fc-next-button .fc-icon-right-single-arrow:after {
        color: #98a6ad;
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after,
    body.dark-only #cal-basic .fc-toolbar .fc-right .fc-prev-button .fc-icon-left-single-arrow:after {
        color: #98a6ad;
    }
    body.dark-only #cal-basic .fc-toolbar .fc-left .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only .fc-button-group .fc-basicWeek-button,
    body.dark-only .fc-button-group .fc-basicDay-button,
    body.dark-only .fc-button-group .fc-agendaWeek-button,
    body.dark-only .fc-button-group .fc-agendaDay-button {
        color: #000 !important;
    }
    body.dark-only .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .btn-light {
        color: #000 !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button {
        color: #fff !important;
    }
    body.dark-only #cal-agenda-view .fc-toolbar .fc-right .fc-button-group .fc-agendaWeek-button:hover {
        color: #000 !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .btn-light,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-basicWeek-button,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .btn-light {
        color: #000 !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button {
        color: #fff !important;
    }
    body.dark-only .basic-calendar .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
    body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover,
    body.dark-only #cal-event-colors .fc-toolbar .fc-right .fc-button-group .fc-month-button:hover {
        color: #000 !important;
    }
    body.dark-only .categories ul li a {
        color: #98a6ad;
    }
    body.dark-only .categories ul li:hover {
        background-color: #1d1e26;
    }
    body.dark-only .socialprofile .likes-profile h5 span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-only .socialprofile span {
        color: #98a6ad;
    }
    body.dark-only .social-status .media .media-body span + span {
        color: #98a6ad;
    }
    body.dark-only .social-status .media .media-body p,
    body.dark-only .social-status .media .media-body .light-span {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view p,
    body.dark-only .timeline-content p {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view .comment-number i,
    body.dark-only .timeline-content .comment-number i {
        color: #98a6ad;
    }
    body.dark-only .filter-cards-view .comments-box .input-group .form-control,
    body.dark-only .timeline-content .comments-box .input-group .form-control {
        border: none !important;
    }
    body.dark-only .filter-cards-view .comments-box .input-group .input-group-append,
    body.dark-only .timeline-content .comments-box .input-group .input-group-append {
        background-color: #1d1e26;
    }
    body.dark-only .social-chat .media-body {
        border: 1px solid #1d1e26;
    }
    body.dark-only .social-chat .media-body:after {
        border-right: 7px solid #262932;
    }
    body.dark-only .social-chat span span {
        color: #98a6ad;
    }
    body.dark-only .details-about .your-details p {
        color: #98a6ad;
    }
    body.dark-only .activity-log .my-activity p {
        color: #98a6ad;
    }
    body.dark-only .bookmark ul li a {
        color: rgba(255, 255, 255, 0.6);
    }
    @media screen and (max-width: 1366px) {
        body.dark-only .earning-card.card .card-body .earning-content {
            border-bottom: 1px solid #374558;
        }
    }
    @media only screen and (max-width: 991.98px) {
        body.dark-only .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
            border-right: 1px solid #374558;
        }
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
            stroke: rgba(255, 255, 255, 0.6);
        }
    }
    @media only screen and (max-width: 767.98px) {
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu svg,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu svg {
            stroke: #fff;
        }
        body.dark-only .page-wrapper.compact-wrapper .left-header .mega-menu .nav-link,
        body.dark-only .page-wrapper.compact-sidebar .left-header .mega-menu .nav-link {
            border-left: 1px solid #374558;
        }
        body.dark-only .page-wrapper.material-icon .page-header {
            background-color: #262932;
        }
        body.dark-only .chart_data_left .card-body .chart-main > div:first-child {
            border-right: 1px solid #374558;
        }
        body.dark-only .chart_data_left .card-body .chart-main > div:nth-child(3) {
            border-right: 1px solid #374558;
        }
    }
    body.dark-only .apexcharts-tooltip.light {
        border-color: #374558;
        background-color: #1d1e26;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-title {
        border-color: #374558;
    }
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-label,
    body.dark-only .apexcharts-tooltip.light .apexcharts-tooltip-text-value {
        color: #fff;
    }
    body.dark-sidebar .toggle-sidebar svg {
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper .sidebar-main-title p {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar
        .page-wrapper.compact-wrapper
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active,
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
        color: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .sidebar-submenu
        li
        .nav-sub-childmenu {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
        background: #262932;
    }
    body.dark-sidebar
        .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content
        li
        a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
    body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
        background-color: #262932;
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
        background: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
        border-bottom: 1px solid #374558;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
        stroke: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
        background-color: #262932;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
        border-bottom: 1px solid #374558;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
        background-color: rgba(115, 102, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
        color: var(--theme-deafult);
        stroke: var(--theme-deafult);
    }
    body.dark-sidebar
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-title
        h5 {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar
        .page-wrapper.compact-sidebar
        .page-body-wrapper
        div.sidebar-wrapper
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li
        .mega-menu-container
        .mega-box
        .link-section
        .submenu-content.opensubmegamenu
        li
        a {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 400;
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
        background-color: rgba(255, 255, 255, 0.2);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
        color: rgba(255, 255, 255, 0.6);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
        color: var(--theme-deafult);
    }
    body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
        color: rgba(255, 255, 255, 0.6);
    }
    body .for-dark {
        display: none;
    }
    body[class*="dark-"] .for-dark {
        display: block;
    }
    body[class*="dark-"] .for-light {
        display: none;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="border-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link,
    .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="border-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
        border-bottom: 1px solid rgba(115, 102, 255, 0.1);
        transition: all 0.3s ease;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 1) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 1):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 2) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 2):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 3) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 3):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 4) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 4):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 5) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 5):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 6) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 6):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 7) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 7):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 8) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 8):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 9) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 9):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 10) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 10):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 11) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 11):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 12) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 12):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 13) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 13):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 14) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 14):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 15) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 15):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 16) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 16):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 17) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 17):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 18) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 18):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 19) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 19):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 20) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 20):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 21) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 21):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 22) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 22):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 23) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 23):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 24) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 24):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 25) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 25):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 26) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 26):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 27) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 27):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 28) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 28):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 29) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 29):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 30) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 30):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 31) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 31):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 32) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 32):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 33) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 33):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 34) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 34):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 35) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 35):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 36) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 36):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 37) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a
        svg {
        stroke: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 37):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 38) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 38):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 39) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 39):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 40) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 40):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 41) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 41):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 42) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 42):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 43) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 43):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 44) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 44):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 45) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 45):hover
        a:hover {
        background-color: rgba(115, 102, 255, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 46) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 46):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 47) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 47):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 48) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 48):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 49) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 49):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 50) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50)
        svg {
        color: #fae475 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 50):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 51) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51)
        svg {
        color: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 51):hover
        a:hover {
        background-color: rgba(244, 244, 244, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 52) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a
        svg {
        stroke: #e77681 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 52):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 53) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 53):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 54) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a
        svg {
        stroke: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 54):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 55) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55)
        svg {
        color: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 55):hover
        a:hover {
        background-color: rgba(169, 39, 249, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 56) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56)
        svg {
        color: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 56):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 57) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57)
        svg {
        color: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a
        svg {
        stroke: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 57):hover
        a:hover {
        background-color: rgba(248, 214, 43, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 58) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a
        svg {
        stroke: #c771fb !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 58):hover
        a:hover {
        background-color: rgba(247, 49, 100, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 59) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59)
        svg {
        color: #b9b3ff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a
        svg {
        stroke: #fff !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 59):hover
        a:hover {
        background-color: rgba(81, 187, 37, 0.15) !important;
    }
    .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"] .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:nth-child(7n + 60) svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60)
        svg {
        color: #fa7b9b !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a
        svg,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a
        svg {
        stroke: #79dc50 !important;
    }
    .page-wrapper.compact-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a:hover,
    .page-wrapper.horizontal-wrapper
        .page-body-wrapper
        .sidebar-wrapper[sidebar-layout="iconcolor-sidebar"]
        .sidebar-main
        .sidebar-links
        .simplebar-wrapper
        .simplebar-mask
        .simplebar-content-wrapper
        .simplebar-content
        > li:nth-child(7n + 60):hover
        a:hover {
        background-color: rgba(220, 53, 69, 0.15) !important;
    }
    .customizer-links {
        position: fixed;
        right: 00px;
        top: 50%;
        z-index: 3;
        transform: translate(0, -50%);
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }
    .customizer-links > .nav {
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
    }
    .customizer-links.open {
        right: 330px;
        border-radius: 8px 0 0 8px;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link {
        padding: 0;
        position: relative;
    }
    .customizer-links .nav-link:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(115, 102, 255, 0.1);
        border-radius: 5px;
        transform: scale(0);
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 12px;
        border-width: 5px 0 5px 5px;
        border-left-color: #e8e6ff;
        opacity: 0;
        transform: scale(0);
        color: #fff;
        left: -10px;
        z-index: 2;
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:hover i {
        color: var(--theme-deafult);
    }
    .customizer-links .nav-link:hover:before,
    .customizer-links .nav-link:hover:after {
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link:hover span {
        opacity: 1;
        right: 48px;
        transform: scale(1);
        transition: all 0.3s ease;
    }
    .customizer-links .nav-link + .nav-link {
        margin-bottom: 5px;
    }
    .customizer-links .nav-link span {
        position: absolute;
        right: -60px;
        width: -webkit-max-content;
        width: max-content;
        text-align: right;
        padding: 7px 10px;
        display: block;
        top: 0;
        border-radius: 5px;
        transform: scale(0);
        transition: all 0.3s ease;
        opacity: 0;
        color: var(--theme-deafult);
        background-color: #e8e6ff;
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }
    .customizer-links .nav-link i {
        font-size: 17px;
        padding: 8px 10px;
        display: inline-block;
        cursor: pointer;
        margin: 0;
        color: gray;
        border-radius: 5px;
    }
    .customizer-contain {
        direction: ltr;
        position: fixed;
        height: calc(100vh);
        top: 82px;
        width: 333px;
        right: -335px;
        background-color: #fff;
        z-index: 8;
        transition: all 0.3s ease;
    }
    .customizer-contain.open {
        right: 0px;
        transition: all 0.3s ease;
        box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    }
    .customizer-contain li {
        position: relative;
    }
    .customizer-contain li.active {
        box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
    }
    .customizer-contain h6 {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px;
    }
    .customizer-contain .customizer-header {
        padding: 15px 25px;
        border-bottom: 1px solid #f4f4f4;
    }
    .customizer-contain .customizer-header .icon-close {
        position: absolute;
        font-size: 18px;
        cursor: pointer;
        font-weight: 900;
        right: 24px;
        color: var(--theme-deafult);
    }
    .customizer-contain .customizer-header h5 {
        font-weight: 700;
        text-transform: uppercase;
    }
    .customizer-contain .customizer-body {
        padding: 15px 25px;
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }
    .customizer-contain .customizer-body .fade:not(.show) {
        display: none;
    }
    .customizer-contain .customizer-body .unlimited-color-layout {
        display: flex;
        align-items: center;
    }
    .customizer-contain .customizer-body .unlimited-color-layout .btn {
        font-size: 15px;
        padding: 4px 20px;
    }
    .customizer-contain .customizer-body .unlimited-color-layout input {
        border: 1px solid #ccc;
        height: 35px;
        width: 35px;
        border-radius: 5px;
        background-color: #fff;
        margin-right: 5px;
    }
    .customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
        height: 70px;
        width: 80px;
        border: 1px solid #f6f7fb;
        border-radius: 5px;
    }
    .customizer-contain .customizer-body .tab-pane > h6:first-child {
        padding-top: 0;
        margin-top: 0;
    }
    .customizer-contain .customizer-body li {
        cursor: pointer;
        opacity: 0.9;
    }
    .customizer-contain .customizer-body li.active {
        opacity: 1;
    }
    .customizer-contain .customizer-body .color-layout {
        height: 35px;
        width: 35px;
        border-radius: 6px;
        display: inline-block;
        margin-right: 3px;
        border: 1px solid #b8b8b8;
        padding: 3px;
    }
    .customizer-contain .customizer-body .color-layout > div {
        background-color: #03565a;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-2"] div {
        background-color: #4831d4;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-3"] div {
        background-color: #d64dcf;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-4"] div {
        background-color: #4c2fbf;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-5"] div {
        background-color: #7c4dff;
    }
    .customizer-contain .customizer-body .color-layout[data-attr="color-6"] div {
        background-color: #3949ab;
    }
    .customizer-contain .customizer-body .dark > li {
        background-color: #2f3c4e;
    }
    .customizer-contain .customizer-body .main-layout > li {
        height: 65px;
        width: 85px;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        display: inline-block;
    }
    .customizer-contain .customizer-body .main-layout > li + li {
        margin-left: 7px;
    }
    .customizer-contain .customizer-body .main-layout .box-layout .body .badge {
        left: -14px;
    }
    .customizer-contain .customizer-body .sidebar-type > li,
    .customizer-contain .customizer-body .sidebar-setting > li {
        height: 70px;
        width: 85px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        display: inline-block;
    }
    .customizer-contain .customizer-body .sidebar-type > li + li,
    .customizer-contain .customizer-body .sidebar-setting > li + li {
        margin-left: 7px;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.bg-dark,
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.sidebar {
        width: 100%;
        height: 10px;
        display: block;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.bg-light,
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"] .body ul li.body {
        height: calc(100% - 10px);
        width: 100%;
    }
    .customizer-contain .customizer-body .sidebar-type li[data-attr="normal-sidebar"].body-compact .body ul li.body {
        width: calc(100% - 25px);
    }
    .customizer-contain .customizer-body .layout-types {
        margin-bottom: -30px;
    }
    .customizer-contain .customizer-body .layout-types > li {
        display: block;
        width: unset;
        height: unset;
        margin-bottom: 30px;
    }
    .customizer-contain .customizer-body .layout-types > li .layout-img h6 {
        padding-top: 0;
        text-transform: inherit;
        text-align: center;
    }
    .customizer-contain .customizer-body .layout-types .box-layout {
        background: unset;
    }
    .layout-grid:not(.customizer-color) li {
        padding: 5px;
    }
    .layout-grid:not(.customizer-color) li > .header {
        height: 9px;
        border-radius: 3px;
        position: relative;
    }
    .layout-grid:not(.customizer-color) li > .header ul {
        position: absolute;
        top: -8px;
        left: 5px;
    }
    .layout-grid:not(.customizer-color) li > .header ul > li {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        top: calc(50% - 3px);
        background-color: var(--theme-deafult);
        margin-right: 2px;
        padding: 0;
    }
    .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
        background-color: var(--theme-secondary);
    }
    .layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
        background-color: #51bb25;
    }
    .layout-grid:not(.customizer-color) li > .body {
        background-color: #f4f4f4;
        height: calc(100% - 16px);
        border-radius: 3px;
        position: relative;
        margin-top: 5px;
        text-align: center;
        line-height: 3.1;
    }
    .layout-grid:not(.customizer-color) li > .body ul {
        height: 100%;
        background-color: #fff;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar {
        width: 18px;
        height: 100%;
        border-radius: 3px;
        display: inline-block;
        margin-right: 3px;
        padding: 0;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
        width: 12px;
    }
    .layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
        width: calc(100% - 19px);
    }
    .layout-grid:not(.customizer-color) li > .body ul .body {
        width: calc(100% - 25px);
        padding: 0;
        margin: 0;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
    .layout-grid:not(.customizer-color) li > .body ul .body .badge {
        position: absolute;
    }
    .jsgrid-filter-row select,
    .jsgrid-filter-row input {
        padding: 6px 10px;
        border: 1px solid #f2f4ff;
        background: #fff;
    }
    .popover {
        background-color: #fff;
        border: none;
        box-shadow: 0 0 20px rgba(115, 102, 255, 0.1);
    }
    .popover .popover-header {
        background-color: #eae8ff;
        color: #03565a;
        border-bottom: none;
    }
    .popover .popover-body {
        color: rgba(43, 43, 43, 0.7);
    }
    .tooltip.bs-tooltip-top .tooltip-arrow:before {
        border-top-color: #eae8ff;
    }
    .tooltip.bs-tooltip-bottom .tooltip-arrow:before {
        border-bottom-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip.bs-tooltip-start .tooltip-arrow:before {
        border-left-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip.bs-tooltip-end .tooltip-arrow:before {
        border-right-color: #eae8ff;
        border-top-color: transparent;
    }
    .tooltip .tooltip-inner {
        background-color: #eae8ff;
        color: #03565a;
    }
    .tooltip .tooltip-arrow:before {
        border-top-color: #eae8ff;
    }
    .dropdown-basic .dropdown .dropdown-content a {
        padding: 6px 16px;
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        border-top: 1px solid #efefef;
        background: #fff;
    }
    .dropdown-basic .dropdown .dropdown-content a:hover {
        background-color: #fff;
    }
    .dropdown-basic .dropdown .dropdown-content .dropdown-header {
        padding: 8px 16px;
        font-weight: 400;
        color: #2c323f;
        font-size: 13px;
    }
    .dropdown-basic .dropup .dropup-content {
        top: auto;
    }
    .default-according .card .card-header {
        padding: 0;
        border: none;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .default-according .card .card-header.bg-primary .btn,
    .default-according .card .card-header.bg-secondary .btn {
        border-color: rgba(0, 0, 0, 0);
    }
    .default-according .card .card-header i {
        position: static;
        position: initial;
        font-size: 20px;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 5px;
    }
    .default-according .card .card-header h5 {
        margin-top: 0;
    }
    .default-according .card .card-header .btn-link {
        padding: 12px 20px;
        width: 100%;
        text-align: left;
        letter-spacing: 0.7px;
        font-family: Roboto;
        border: 1px solid #efefef;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .default-according .card .card-body {
        padding: 15px 20px;
        line-height: 22px;
        font-size: 13px;
        border-color: #efefef;
        border-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #2c323f;
    }
    .nav-tabs {
        border-bottom-color: #efefef;
    }
    .nav-tabs .nav-bottom .nav-item .nav-link.active {
        border-color: #efefef;
    }
    .nav-tabs .nav-item.show .nav-link {
        font-weight: 500;
    }
    .nav-tabs .nav-link {
        color: #2c323f;
    }
    .nav-tabs .nav-link.active {
        font-weight: 500;
    }
    .nav-tabs.nav-bottom .nav-item .nav-link.active {
        border-color: #ecf3fa #ecf3fa #fff;
    }
    .dropdown-menu {
        box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        border: none;
        padding: 0;
    }
    .dropdown-menu .dropdown-item {
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid #efefef;
        background: #fff;
    }
    .dropdown-menu .dropdown-item:hover {
        background-color: #fff;
    }
    .dropdown-menu .dropdown-divider {
        margin: 0;
        border-top: 1px solid #cccccc;
    }
    .border-tab .nav-tabs .nav-item .nav-link.active {
        border-bottom: 2px solid #03565a;
    }
    .border-tab .nav-tabs .nav-item .nav-link.show {
        border-bottom: 2px solid #03565a;
    }
    .border-tab .nav-tabs .nav-item .nav-link:focus {
        border-bottom: 2px solid #03565a;
    }
    .tabbed-card .nav-tabs .nav-item .nav-link {
        top: 17px;
    }
    .timeliny .timeliny-dot::before {
        font-size: 18px;
    }
    .form-builder-header-1,
    .form-builder-2-header {
        background-color: #f7f6ff;
    }
    .form-builder-header-1 .nav-primary .nav-link,
    .form-builder-2-header .nav-primary .nav-link {
        color: #2c323f;
        font-weight: 400;
    }
    .form-builder-header-1 .nav-primary .nav-link.active,
    .form-builder-2-header .nav-primary .nav-link.active {
        color: #fff;
    }
    .form-builder-header-1 h6,
    .form-builder-2-header h6 {
        margin-bottom: 0;
        font-weight: 400;
        line-height: 36px;
    }
    .component .input-group .btn {
        line-height: 32px;
        text-transform: capitalize;
    }
    .form-builder .drag-box fieldset {
        margin-top: 0;
    }
    .login-card .login-main .theme-form label {
        font-size: 14px;
    }
    .login-card .login-main .theme-form .show-hide {
        top: 50%;
    }
    .faq-accordion .card .btn-link svg {
        margin-left: 20px;
    }
    .job-filter .faq-form .form-control {
        font-size: 14px;
    }
    .job-filter .faq-form .search-icon {
        width: 16px;
        height: 16px;
    }
    
    /* ------------------------------------- */
    /* .left-header ul li svg {
        fill: #fff;
    }
    
    ul.nav-menus {
        margin-top: 12px;
    }
    
    .card1{
        padding: 10px;
        background: #fff ;
    }
    .bg-primary1 th{
        background-color: transparent !important;
        color: #fff !important ;
    }
    .css-1uu34zr, .css-b8tju9, .MuiBox-root.css-1l9ohcm{
        border: none !important;
        border-radius: 10px;
    }
    .btn-primary1{
        padding: 10px 25px !important;
    }
    .btn-primary1:hover{
        color: #fff !important;
    }
    .form-control, input[type=text]{
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #696f79 !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        min-height: 47px !important;
        width: 100%;
    padding: 16px 10px;
    font-size: 18px;
    font-family: 'DM Sans';
    margin-top: 10px;
    display: inline-block;
    }
    input[type=text]{
        font-size: 15px !important;
    }
    .form-select {
       
        display: block;
        width: 100%;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        vertical-align: middle;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    } */
.sidebar_v0 {
  width: 300px;
  height: 100vh;
  background-color:white;
 
}
.fs_v021{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  margin-left: 20px;
}
.fs_v021>.fsbtn_v00{
  height:60px !important;
  width: 50px !important;
  background-color:#48C9B0 ;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.department{
  display: flex;
  background-color:#f5f5f5 ;
  color:black;
  border-radius: 10px;
  margin-top: 10px;
  padding: 13px;
  width: 85%;
  margin-left: 20px;
}

.department_inactive{
  display: flex;
  background-color:white ;
  color: #8B909A;
  border-radius: 10px;
  margin-top: 10px;
  padding: 13px;
  width: 85%;
  margin-left: 20px;
}

.fs_v021>.fsbtn_v00:hover{
  background-color:#48C9B0 ;
}

.fstitle_v4{
  margin-left: 20px;
  margin-top: 20px;
}

/*  */
.workspace_v3{
display: flex;
flex-direction: row;
}
.workspace_v3>.circle_V3 {

height: 30px;
width: 30px;
border-radius: 15px;
border: 2px solid #03565A;

text-align: center;
}
.vl_V3 {
border-left: 2px solid #D4D8D6;
height: 15px;
margin-left: 14px;
}
.Layout_v3{

font-size: 0.9rem;
margin-top: 4px;
color:black;
margin-left: 2vh;
font-weight: 500;
}
.Layout_v3:active {
color: #03565A;
}
.Header_v8{
margin-top: 20px;
/* margin-left:20px ; */
font-weight: 600;
}
.HeaderOne_V1{
display: flex;
flex-direction: row;
/* margin-left: 20px; */
margin-top: 10px;
/* width: 80%; */
}
.card_overview{
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
}
.Card_v9{
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
border-radius: 100%;
background-color: #FED8B1;

}
.wpTeampmember>.btn_x1{
margin-top: 15px;
background-color:#03565A ;
text-transform: none;
margin-left: 15px;

}
.wpTeampmember>.btn_x2{
margin-top: 15px;
text-transform: none;
background-color:#f5f5f5 ;
color: black;

}
.wpTeampmember>.btn_x2.btn:hover{
background-color: white;

}
.text_v2{
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 20px;


}
.importBadge{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: #00A36C;
  background-color: #F2FEFF;

}

.importBadge_1{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: orange;
  background-color:#FFF6E6;

}
.importBadge_2{
  height:35px ;
  width: 146px;
  background-color:#FFEDED ;
  padding-top: 7px;
  border-radius: 10px;
  color: red;
  padding-inline: 30px;

 
}
.importBadge_4{
  height:35px ;
  width: 146px;
  background-color:#EDFFF3;
  padding-top: 7px;
  border-radius: 10px;
  color: green;
  padding-inline: 42px;

 
}
/* .importBadge_4{
  height:35px ;
  width: 146px;
  background-color:#FFEDED ;
  padding-top: 7px;
  border-radius: 10px;
  color: red;
  padding-inline: 30px;

} */
.importBadge_3{
  height: 35px;
  width: 140px;
  padding-inline: 30px;
  padding-top: 7px;
  border-radius: 10px;
  color: #1AA260;
  background-color:#EDFFF3;
}
.Maincard_V2{
width: 300px;
height: 120px;
}
.importBadge{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: #00A36C;
background-color: #F2FEFF;

}

.importBadge_1{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: orange;
background-color:#FFF6E6;

}
.importBadge_2{
height:35px ;
width: 146px;
background-color:#FFEDED ;
padding-top: 7px;
border-radius: 10px;
color: red;
padding-inline: 30px;


}
.importBadge_4{
height:35px ;
width: 146px;
background-color:#EDFFF3;
padding-top: 7px;
border-radius: 10px;
color: green;
padding-inline: 42px;


}
/* .importBadge_4{
height:35px ;
width: 146px;
background-color:#FFEDED ;
padding-top: 7px;
border-radius: 10px;
color: red;
padding-inline: 30px;

} */
.importBadge_3{
height: 35px;
width: 140px;
padding-inline: 30px;
padding-top: 7px;
border-radius: 10px;
color: #1AA260;
background-color:#EDFFF3;

}

.not_started{
width:140px ;
height: 35px;
background-color: #F2FEFF;
padding-inline: 30px;
padding-top:7px ;
border-radius: 10px;
color:#00A36C;

}






/* workspacepreset design start */
.wpcontent{
margin-top: 23px;
}
.wprow{
margin-top:10px;
}
.wpsecondrow{
margin-top: 20px;
}
.wpTypography{
 margin-top:10px;
/* color:red; */
color:black;
font-size: 14px
}
.wpcard-pad{
  padding-bottom: 30px !important;
}
.wpTeampmember{
margin-left: 23px;
}
.wpTeampmember input:-ms-input-placeholder{
padding-left: 23px;

}
.wpTeampmember input::placeholder{
padding-left: 23px;

}
.badgeReject{
background-color: #FFEDED;
color: red;
}
.badgeActive{
background-color: #EDFFF3;
color:green;
}
.badgePending{
background-color: #FFF6e6;
color:#cc8000;

}

.badgenotstarted{
background-color: #EDFFF3;
color:blue;
}
.wpTableContainer{
margin-top:23px;
}
.wpSearchicon{
  margin-top: -86px;
   margin-left: 12px;
   /* padding: 16px 45px; */
}

/* end workspacepreset */

/* workspacedetails design start */
.wdContainer{
margin-top: 45px;
}
.wdlabel{
margin-top: 23px;
color: gray;
}
.wdcard{
margin-top: 23px;
}
.wdcyear{
 margin-top: 12px;
}
.wddate{
font-size:14px;
}
.wdpyear{
margin-left: 32px;
}
.wd2ndcard{
 margin-left: 70px
}
.wdButton{
margin-top: 63px;
}

.wp-h{
 
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.wp-sechead{
font-weight: 700;
font-size: 16.35px;
line-height: 25.43px;
width: 226px;
height: 26px; 
}
.wp-content{
  font-weight: 500;
  font-size: 14.53px;
 
}
.wp-card-content{
  font-weight: 700;
  font-size: 10.9px;
  line-height: 18.16px;
  color: gray;
}
/* End financial */
/* workspace register btn active */

/*  */

/* ashis css */
.tabtoggle{
 
 border-radius: 0px !important;
}
.css-1aquho2-MuiTabs-indicator{
  
background-color: inherit !important;
}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color:black;
  background-color:#03565A;
}
.css-19kzrtu {
  padding: 0 !important;
}
/* consoldation work css */
.fs_logo_1{
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: orange;
  
}
.image_fs_1{
  height: 30px;
  width: 30px;
  margin-top: 34px;
  margin-left: 34px;
 

}
.consol_fs{
display: flex;
justify-content: center;
margin-top: 20px;

}
.fs_bttn_v1{
  width: 270px;
  text-transform: none;
  color: black;
  background-color: white;
  border: 2px solid green;

  
}
.one_Box{
  height:80px ;
  width: 100%;
  background-color: white;
  margin-top: 10px;

}
.wdButton>.save_btn{
  width: 150px;
  height: 40px;
  border-radius: 5px;
  text-transform: none;
  background-color: #03565A;
  color: white;
 
}
.wdButton>.save_btn:hover{
 
  background-color: #03565A;
 
 
}
/* work in workspacedateilsv2 */
.choice_date{
  margin-top: 0px;
  width: 210px;
  height: 40px;
  border: "2px solid gray";
  border-radius: 5px;
}
/* work in consolidation page */
.my_button{
  display: flex;
  justify-content: flex-end;

}
.my_button>.my_button_v1{
  margin-top: 16px;
  background-color: #03565A;
  text-transform: none;
  margin-right: 30px;
  width: 250px;
  height: 45px;
  color: white;
  border-radius: 10px;

}
.my_button>.my_button_v1:hover{
  background-color: #03565a;
 

}
.consol_fs{
}
.consol_fs>.btn_consol{
  width: 280px;
  height: 40px;
  text-transform: none;
  color:  #03565A;
  border: 2px solid green;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
 
}
.consol_fs>.btn_consol:hover{
  background-color:white;
 
 
}
.tabtoggle{
 
  border-radius: 0px !important;
 }
 .css-1aquho2-MuiTabs-indicator{
   
 background-color: inherit !important;
 }
 
 
 .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
   color: black ;
   background-color:#03565A;
 }
 .css-19kzrtu {
   padding: 0 !important;
 }
.Active_v1:active{
  background-color: red;

}

.table_btn{
  width: 150px;
  height: 40px;
  background-color: #F2FEFF ;
  border: none;
  border-radius: 5px;
  color: #2DA6AC;
}
.table_btn_v1{
  width: 150px;
  height: 40px;
  background-color: #F2FEFF ;
  border: none;
  border-radius: 5px;
  color: #2DA6AC;
}
.progress_btn{
  width: 150px;
  height: 40px;
  background-color:#FFF6E6 ;
  border: none;
  border-radius: 5px;
  color:#CC8000;
}
.identified_btn{
  width: 150px;
  height: 40px;
  background-color:#FFEDED;
  border: none;
  border-radius: 5px;
  color:#CD3030;
}
.verified_btn{
  width: 150px;
  height: 40px;
  background-color:#EDFFF3;
  border: none;
  border-radius: 5px;
  color:#068133;

}



.chek_btn>.btn_chek43 {
  width: 130px;
  text-transform: none;
  background-color: white;
  border: 2px solid #03565A;
  color: black;
  height: 40px;
  border-radius: 10px;
}

.chek_btn>.btn_chek43:hover {
  background-color: white;

}

.chek_btn>.btn_chek53v2 {
  width: 130px;
  text-transform: none;
  background-color: white;
  border: 2px solid #03565A;
  color: black;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
}

.chek_btn>.btn_chek53v2:hover {
  background-color: white;

}
.sidebar_v2 {
  width: 100%;
  height: 100vh;
  /* max-height: 100%; */
  background-color: white;
  /* margin-bottom: 10px; */
}

.img-v2 {
  width: 9rem;
  height: 9rem;
  margin-left: 4vh;
}

.first1 {
  display: flex;
  flex-direction: row;
  margin-left: 5vh;
}

.first>.icon1 {
  height: 1rem;
  width: 1rem;
  margin-top: 12px;

}

.layout1 {
  font-size: 0.9rem;
  color: #03565A;
  margin-left: 2vh;



}

.layout-v2 {

  font-size: 0.9rem;
  margin-top: 4px;
  color: black;
  margin-left: 2vh;
  font-weight: 500;



}

.layout-v2:active {
  color: #03565A;
}

.workspace-v2 {
  display: flex;
  flex-direction: row;
  margin-left: 31px;
}

.workspace-v2>.circle-v2 {

  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 2px solid #03565A;
background-color: #03565A;
color: white;
  text-align: center;
}

.workspace-v2>.circle-v3 {

  height: 30px;
  width: 30px;
  border-radius: 15px;
  /* border: 2px solid #03565A; */

  text-align: center;
}

.vl-v2 {
  border-left: 2px solid #D4D8D6;
  height: 15px;
  margin-left: 46px;
}

/*  */
.main_container {
  width: 100%;
  height: 100%;

}

.toptext-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 30px;
  /* margin-left:3rem; */
}

.workinghead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
  color: #111111;
  margin-left: -22px
}

.qwerty {
  border: 2px solid red;
  height: 10rem;

  border-radius: 20px;
  /* border-radius: ; */
  /* borderRadius: "20px" */


}

.card-selected {
  height: 10rem;

  border-radius: 20px;
  border: 1px solid blue;
}

.card-v2 {

  width: 100%;
  height: 10rem;
  border-radius: 10px;
}

.card-v2-border {
  /* width: 15rem; */
  height: 10rem;
  border: 2px solid #03565A;
  border-radius: 10px;
}

.card-vv2 {

  margin-top: 20px;

}

.card-v-2 {
  width: 100%;
  height: 10rem;
  border-radius: 20px;
  /* border: 1px solid blue; */
 
}

.maincard-v2 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 58rem;
  margin-top: 15px;

}

.chekbox-v2 {
  margin-left: 8px;

  font-size: 14px;
  color: black;
  margin-top: 5px;

}

.chekbox-v3 {
  margin-left: 10px;
  font-size: 15px;
  color: gray;
  margin-top: 20px
}

.backicon {
  display: flex;
  flex-direction: row;
  color: #03565A;
  font-weight: 550;
  margin-top: 6px;
}

.nextbtn-v2 {
  display: flex;
  justify-content: flex-end;
}

.btn-v2 {
  /* height: 40px; */
  text-transform: none;
  font-size: 1rem;
  /* width: 300px; */
  background-color: #03565A;


}

.bttn-v2 {
  height: 40px;
  text-transform: none;
  font-size: 1rem;
  width: 200px;
  background-color: #03565A;


}

.title {
  color: gray;
  font-size: 13px;
  margin-left: 3rem;
  margin-top: 10px;

}

.drop_down {
  max-height: 45vh;
  overflow-y: scroll;
  width: 100%;
}

.drop_down1 {
  margin-top: 20px;
  width: 95%;
}

.dropp_down {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn_group {
  margin-top: 0px;
}

.btn_group>.skip_btn {
  border: 2px solid #045F5F;
  margin-right: 25px;
  color: #045F5F;
  font-weight: 550;
  width: 150px;

}

.main_table {
  display: flex;
  justify-content: space-between;
  width: 94%;
  /* height: 100%; */
  margin-left: 35px;
  margin-top: 160px;
  margin-bottom: 20px;
}

.main_tablev1 {
  display: flex;
  justify-content: space-between;
  width: 94%;
  /* height: 100%; */
  margin-left: 35px;
  margin-top: 40px;
  /* margin-bottom: 20px; */
}

.title_0ne {
  color: #045F5F;
  font-size: 17px;
  font-weight: 500;
}

.hr {
  width: 76%;
  margin-left: 50px;
  margin-top: 10px;
}

/* style={{ width: "76%", marginLeft: "50px", marginTop: "40px" }}  */
/*  */
.date-v2 {
  width: 252px;
  margin-top: 8px;
  /* // style={{ width: "252px", marginTop: "8px" }} */

}

.datebtn {
  width: 252px;
  /* background-color: #03565A; */


}

.title-v2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;
  margin-left: 3rem;
  margin-top: 4rem;

}

.inputbox {
  width: 75%;

}

.backicon-v2 {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  color: #03565A;
  font-weight: 550;
  margin-left: 3rem;
  margin-top: 14rem;

}

.inputbox-v2 {
  display: flex;
  /* width: 500px; */
  /* margin-left: 30px; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 10px;
  background-color: white;
  border: 1px solid gray;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  border-radius: 5px;

}

.workcercle-v2 {
  display: flex;
  flex-direction: row;
  /* margin-left: 31px; */

}

.dropdwn_v2 {
  /* display: flex; */
  /* justify-content: space-between; */
  /* width: 80%; */
}



/* import Register */


/*  */
.workcercle-v2 {
  display: flex;
  /* flex-direction: row; */
  /* margin-left: 31px; */
}

.circle-v22 {

  height: 30px;
  width: 30px;
  margin-top: 10px;
  border-radius: 100%;
  color: green;
  background-color: #c6ecc6;

  text-align: center;
}

.circle-v222 {
  height: 30px;
  width: 30px;
  margin-top: 25px;
  color: green;
  background-color: #c6ecc6;
  border-radius: 15px;
  /* border: 2px solid gray; */
  margin-left: 50px;

  text-align: center;

}

.backicon-v22 {
  /* display: flex;
  flex-direction: row;
  margin-top: 40px;
  color: #03565A;
  font-weight: 550;
  margin-left: 3rem;
  margin-top: 4rem; */

}

.nextbtn-vv2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* flex-direction: row; */
  width: 97%;

}

.nextbtn-vv2>.btndgn {
  color: #03565A;
  border: 2px solid #03565A;
  background-color: white;
  font-weight: 600;
  width: 150px;
  margin-right: 25px;
  margin-top: "-28px";


}



/* import Register Start */
.importAccordian {
  width: 990px;
  margin-left: 32px;
  margin-top: 19px;

}

.importBadge {

  background-color: #EDFFF3;
  color: green;
  border-radius: 3px;
  margin-top: -82px;
  margin-left: 160px;

}

.importCheckboxV2 {
  margin-left: 36rem;
}

.importDate {
  margin-left: 22px;
}

.importCalender {
  color: gray;
  margin-left: 15px;
}


/* end Register */

/* AddCompanies Style Start */
.companiesAccordion {
  width: 990px;
  margin-left: 32px;
  margin-top: 9px
}

.companiesAccordionDetail {
  margin-top: -30px;
  margin-left: 20px;
}

/* AddCompanies Style End */

/* Import Preset start */
.presetCard {
  width: 990px;
  margin-left: 32px;
  margin-top: 19px;
}

.presetCardContent {
  font-weight: bold;
  width: 101%;
  justify-content: space-between;

}

.presetContentSpan {
  margin-left: -2px;
  /* text-transform: bold; */
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #111111;
  margin-top: 2px
}

.presetContentCalender {
  margin-left: 22px;
}

.presetArrow {
  color: #03565A;
  margin-left: -22px;
  margin-top: 22px;

}

/* import Preset End */
.popover_v1 {
  margin-left: 22px;
}

.impbtn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  background: #03565A;
  border-radius: 6px;
  border: 2px solid #03565A;
  color: #fff;
  margin-right: 15px;
  width: 290px;
}

.impbtn:hover {
  background: #2DA6AC;
  border: 2px solid #2DA6AC;
  border-radius: 6px;

  color: #FFFFFF;
}

.impbtn2 {
  width: 290px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  background: #fff;
  border: 2px solid #03565A;
  border-radius: 6px;
}

.impbtn2:hover {
  background: #fff;
  border: 2px solid #2DA6AC;
  border-radius: 6px;
  color: #2DA6AC;
}

.imprhead {
  margin-top: -10px;
  font-weight: 600;
  font-size: 30px;
  color: black;
  margin-left: 33px;
}

.imrh4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
}

.impwhy {
  margin-left: -22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;
}

.ipmbtn1 {
  margin-left: 473px;
  border: 2px solid #03565A;
  color: #03565A;
  text-transform: none;
}

.impbtn2 {
  margin-left: 25px;
  background-color: #03565A;
  color: white;
  border: 2px solid #03565A;
  text-transform: none;
}

.imptoptex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 94%; */
  margin-top: 30px;
}

.containerpad {
  padding-left: 43px;
}

.imrstep {
  margin-left: 33px;
}

.imrl {
  margin-left: 750px;

}

.imrl>span {
  color: #03565A;
  font-weight: 550;
}

.imprwhy {

  margin-left: 2rem;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;

}

.imr {
  width: 937px;
}

.impr-contenth4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: -24px;
  margin-left: 26px;
}

.imprstep {
  margin-left: -22px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #929292;
}

.imprl {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.imprl-span {}

.imprcheck {
  margin-top: -30px;
  margin-left: 20px;
}

.imprcomp {
  margin-top: 20px;
}

.invbtndiv {
  margin-left: 278px;
}

.db-v2-head {
  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
  /* width: 439px; */
}

.dbv2-dhead {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;
}

.ir-heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.ir-con-head {
  line-height: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #717171;
}

.ir-heading2 {
  font-weight: 700;
  font-size: 16px;
  size: 700;
  line-height: 24px;
  color: #111111;
  margin-top: 1px;
}

.ircon-head {
  font-weight: 700;
  font-size: 14px;
  /* line-height: 20px; */
  color: #696F79;
}

.ir-remark {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* margin-top: -36px; */
  margin-bottom: -7px;
  color: #696F79;

}

.iw-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #696F79;
}

.itmstep {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #929292;
}

.itmhead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;
}

.itmpara {

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #939393;

}

.itmlab {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #696F79;
}

/* 16-08-2023 */
.main_bar {
  background-color: #f5f5f5;
}

.main_top {
  margin-top: 90px;
}

.input_box {
  width: 518px;
  margin: 0 auto;


}

.password-signin label {
  color: #696F79;
  font-weight: 600;
}

.password-signin {
  display: inherit;
}

.row_box {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;

}

.btn_main {
  margin-top: 30px;
  margin-bottom: 10px;


}

.btn_main>.create_btn {

  background-color: #03565A;
  color: white;
  height: 65px;
  width: 100%;
  font-size: 20px;

}

.btn_main>.create_btn:hover {

  background-color: #03565A;


}

.w-20 {
  float: left;
  width: 20% !important;
}

.logiin-link-nav {
  width: 60%;
  float: left;
  margin-top: 28px;
}
.logo-img{
  width:123px;
  height: 50px;
  margin-top:15px;
}
.navbarend-link{
  text-align: right;
  color: #fff;
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 500;
}
.wp-header-step{
  color: #929292;
  /* Caption/Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}
.wp-header-help{
  color: #343434;
text-align: right;
/* ParagraphMedium/Medium */
font-family: DM Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.wp-header-title{
  color: #111;
/* H4/Bold */
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 33.6px */
letter-spacing: 0.56px
}
.wp-header-sub-title{
  color: #939393;
/* ParagraphMedium/Medium */
font-family: DM Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.wp-setup-form-label{
  color: #696F79;

/* ParagraphSmall/Bold */
font-family: DM Sans;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */
}
.wp-setup-form-input{
  border: 1px solid #8692A6 !important;
  background: #FFF;
}
.main-workspace-content{
  padding: 0px 40px;
}
.wp-selection, .wp-selection small{
  font-weight: 600;
}

.wp-datepicker .MuiTextField-root{
  width: 100%;
}
.wp-datepicker input {
  padding: 10px;
}

.sidbar-active{
  width: "29px";
  height: "29px";
  background-color: "#f3dbbe";
  border-radius: "50%";
}
.active-rounded{
  width: "11.74px";
  height: "11.74px";
  background-color: "#fdc27b";
  border-radius: "50%";
  position: "relative";
  top: "9px";
  left: "9px";
}


.sidbar-active{
  height: 35px;
  width: 35px;
  /* / border-radius: 15px; / */
  background-color: #f3dbbe;
  border-radius: 50%;
  margin-left: -4px;
  margin-top: -2px;
  
  
}
.sidbar-active>.active-rounded{
  width: 12px;
  height: 12px;
  background-color: #fdc27b;
  border-radius: 50%;
  position: relative;
  top: 11px;
  left: 11px;
}
/* pop over design */
/* Popover.css */

.popover-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  /* margin-right: 20px; */
  /* margin-top: 65px; */
  /* border: 2px solid red; */
}

.popover {
  /* margin-left: 3px; */
  position: relative;
  min-width: 290px;
  height: 280px;
  background-color: white;
  border-radius: 10px;
  margin-top: 150px;
  border: 2px solid #8692A6;
}

.popover-arrow {
  position: absolute;
  top: -20px;
  left: 37px;
  height: 30px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  width: 0;
  height: 0;
  -webkit-filter: drop-shadow(0 -2px 2px #AAA);
}
.calendar-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the positioning as needed */
  transform: translateY(-50%);
}
/* material ui tabs */
.custom-tabs-class .custom-tab-class.Mui-selected {
  background-color: white;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.circle {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 100%; /* Makes the div circular */
  background-color: #FDC27B;
}
/* tab */
.custome-active-tab {
  color: black !important;
}


body {
  background-color: #f5f5f5 !important;
}

.backk {
  margin-top: 130px;
  color: #03565A;
  display: flex;
  flex-direction: row;
  height: 1.3rem;
  width: 2rem;
  margin-left: 4.5%;
}

.backk>.iconn {
  color: #03565A;
  height: 1.3rem;
  width: 2rem;

}

.companies {
  margin-left: 5%;
  margin-top: 10px;
}

.companies>.text {
  font-weight: bold;
}

.paragraph {
  color: gray;
  margin-left: 5%;
  font-size: x-small;

}

.paragraph>.fontsize {
  font-size: 0.8rem;
}

.searchbar {
  /* height: 1rem; */
  margin-left: 5%;
  margin-top: 28px;
  /* width: 50.5%; */


  /* style={{ marginLeft: "5%", marginTop: 10 }} */
}

.searchbar>.searchbox {
  width: 50.5%;

  background-color: white;

}

.figma {
  margin-top: 15px;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48%;
  height: 44px;
  border-radius: 10px;
  background-color: rgb(3 89 93 / 5%);

}

.secondfigma {
  margin-top: 15px;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  height: 44px;
  border-radius: 10px;
  background-color: rgb(3 89 93 / 5%);
}



.figma>.icon {
  height: 50;
  width: 20;

}

.MuiChip-root {
  color: #03565A !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  padding: 0px 10px !important;
  border: 2px solid #03565A !important;
  background: rgba(3, 86, 90, 0.10);
}

.MuiChip-deleteIcon {
  color: #03565A !important;
  font-size: 16px !important;
  cursor: pointer;
  margin: 0 5px 0 -6px;
  font-weight: 500;
}

.button {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.button>.lastbtn {

  width: 150px;
  height: 56px;
  background-color: white;
  margin-left: 20px;
  color: #03565A;
  border: 2px solid #03565A;
  border-radius: 6px;


}

/* .btnsize{
    margin-top: 15px;
  
    font-size: 1rem;
    text-align: center;
  
    
    
  } */
.endbtn {
  margin-left: 60px;
  font-weight: 600;
  /* style={{ marginLeft: "60px", fontWeight: "600" }} */
}

.button>.btnsize {

  width: 150px;
  height: 55px;
  background-color: white;
  color: #03565A;
  text-transform: none;
  font-size: 1rem;
  margin-left: 25px;
  /* font-size: medium; */
  /* border-radius: 5px; */



}
.save-workspacesetting-btn{
  color: white !important;
  background-color: #03565a !important;
  border-radius: 4px;
  border: none;
  width: 198px;
  height: 45px;
  text-transform: capitalize !important;
  margin-left:80px !important;
}
.viewlog-workspacesetting-btn{
  color: white !important;
  background-color: #03565a !important;
  border-radius: 4px;
  border: none;
  width: 198px;
  height: 45px;
  text-transform: capitalize !important;
}

.button>.btnsize:hover {
  background-color: white;

}

.button>.continue {

  width: 250px;
  height: 55px;
  background-color: #03565A;
  color: white;
  text-transform: none;
  font-size: 1rem;
  border-radius: 5px;


}

.button>.continue2 {

  width: 250px;
  height: 55px;
  border-radius: 7px;
  background-color: gray;
  color: white;
  text-transform: none;
  font-size: 1rem;


}

.button>.continue:hover {
  background-color: #03565A;
}

.button>.continue2:hover {
  background-color: gray;
}

.css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.5rem !important;
}

/* dashboard css */
.dahboardHeaderV2 {
  margin-top: 30px;
  padding: 0 70px;
}

.dbv2datareg {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.dv2datapar {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.dahboardCardV2 {
  /* width:1434px; */
}

.dashboard-h3 {
  color: #000;
  text-align: center;
  /* H3/Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 38.4px */
  letter-spacing: 0.64px;
}

.dbv2hp {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

}

/* .dashboardlogoV2{
    display: flex;
    flex-direction: row;
    margin-left: 254px;
    margin-top: 52px,
} */
.dashboardContainerV2 {
  margin-top: 7rem;
}

.colv2 {

  margin-left: -150px;

}

.dashboardButton1 {
  background-color: #03565A;
}

.dashboardButton2 {


  background-color: white;
  color: #03565A;
  border: 2px solid #03565A;
  margin-left: 10px;
}

.col2V2 {
  margin-top: -18px;
}

.db-empty-company-card {
  padding: 36px 0px;
}

.db-wp-empty-p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.db-wp-empty-logos {
  padding: 35px 0px;
}

.wp-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  overflow: auto;
}

.wp-modal-box-add-company {
  height: calc(80vh);
  width: 789px;
}

.wp-modal-box-select-ir {
  height: calc(85vh);
  width: 600px;
  background-color: #F8F8F8 !important;
}

.wp-modal-box-select-ir:focus-visible {
  outline: none !important;
}

.wp-modal-box-select-ir-closebtn {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  float: right;
}

.wp-add-comp-modal-header {
  color: #000;
  /* H5/Bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: 0.48px;
}

.wp-add-comp-modal-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0px
}

.wp-add-comp-modal-search {
  width: 100%;
  margin-top: 15px !important;
}

.wp-add-comp-modal-input {
  width: 100%;
}

.MuiInputBase-input {
  margin-top: 0px !important;
  border-radius: 6px;
  border: 1px solid #8692A6;
  background: #FFF;
}

.wp-comp-search-no-reslt-msg {
  border-radius: 12px;
  background: rgba(3, 86, 90, 0.05);
  margin-top: 8px;
  padding: 13px;
  color: #03565A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.wp-comp-added-p {
  color: #000;

  /* ParagraphMedium/Medium */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.IR-tabs {
  background-color: #f4f4f4;
}

.css-ttwr4n {
  background-color: #f4f4f4 !important;
  position: static !important;
  position: initial !important;
}

.IR-tabs-card {
  border-top-left-radius: 40px !important;
  /* border-top-left-radius: 180px 180px !important; */
  padding: 20px !important;
}

.wp-IR-tabs {
  border-radius: 8px;
}

.wp-IR-tabs>.MuiTab-root {
  border-radius: 8px;
  color: red !important;
  font-size: 15px;
  font-weight: 500;
}

.cardbuttonv2 {

  background-color: white;
  color: #03565A;
  border: 2px solid #03565A;

}

.container2v2 {
  margin-top: 7rem;
}

.cardStyleV2 {
  height: 300px;
}

.dahboardCard2V2 {
  /* width: 35rem; */
  max-height: 325px;
  min-height: 325px;

}

.dahboardicon {
  color: gray;
  margin-left: 30px;
}

.ir-empty-cardheader {
  padding: 0;
  background-color: #fff;
  border-bottom: none;
}

/* .dashboardvi{
  border-right: 3px solid gray;

}  */
.dashboardheader {
  margin-left: 65px;
}

.dashboardchead {
  margin-left: 20px;
}

.dashboardnavit {
  background-color: #DDDDDD79;
}



.dbinput input:-ms-input-placeholder {

  padding-left: 40px;
}



.dbinput input::placeholder {

  padding-left: 40px;
}

.dbicon {

  margin-top: -46px;
  margin-left: 7px;

}

.dbbtdiv {
  margin-top: 61px;
  margin-left: 23px;
}

.dvtypo {
  font-size: 13px;
}

.dbwcontainer {
  margin-left: 263px;
}

.dbwscontain {
  margin-left: 112px;
}

.dbwcontain2 {
  margin-left: 127px;
}

.dbwbtn {
  margin-left: 368px
}

.dbwhead {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.dbwcardhead {
  color: #000;
  /* H5/Semibold */
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 28.8px */
  letter-spacing: 0.48px;
  display: inline-block;
}

.abcd1111 {
  float: right;
}

.dbwcardhead-viewall {
  color: #03565A;
  text-align: right;
  float: right;
  /* ParagraphMedium/Bold */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.db-wp-company-list span {
  color: #000;
  /* ParagraphMedium/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.wp-modal-box-add-company-form {
  height: calc(80vh);
  width: 689px;
}

.register-modal-card {
  box-shadow: none;
  border-radius: 20px !important;
  background: #FFF;
  padding-top: 15px;
}

.register-modal-card>.MuiCardContent-root {
  padding-top: 10px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0px !important;
}

.register-modal-card-active {
  border: 2px solid #03565A;
}

.dbwcardpar {
  color: #000;

  /* ParagraphMedium/Regular */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  /* 150% */
  padding-bottom: 10px;
}

.dbwcard2head {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
}

.badge_css {
  background-color: #EDFFF3;
  border-radius: 5px;
  color: #068133;
  padding: 0px 10px;
}

.addcomphead {
  font-weight: 700;
  font-size: 28px;
  line-height: 33.6px;

}

.addcomppara {
  color: #939393;
  font-size: 16px;
  line-height: 24px;
}

.addcompconthead {
  margin-top: -25px;
  margin-left: 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  height: 10px;
}
.addcompcontheadv2 {
  margin-top: -25px;
  margin-left: 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  height: 10px;
  margin-left: 44px;
}
.toggl-icon{
  margin-left: 10px;
}


.fs-head {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.hide {
  display: none;
  position: fixed;
  margin-top: -1px;
}

.myDIV:hover+.hide {

  display: block;
  flex-direction: row-reverse;
  color: black;
  font-size: 13px;

}

.bdrchk {
  border: solid 1px #f00;
}

.wp-btn-fill {
  color: #fff !important;
  padding: 10px 60px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background: #03565A !important;
  border-color: #03565A !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
}

.wp-btn-fill:hover,
.wp-btn-fill:focus,
.wp-btn-fill:active {
  color: #fff !important;
  background: #03565A !important;
  border-color: #03565A !important;
  box-shadow: none
}

.wp-btn-no-fill {
  color: #03565A !important;
  padding: 9px 59px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  border: 2px solid #03565A !important;
  background: #FFF !important;
  font-family: DM Sans !important;
  font-size: 14px !important;
}

.wp-btn-no-fill:hover,
.wp-btn-no-fill:focus,
.wp-btn-no-fill:active {
  color: #03565A !important;
  background: #FFF !important;
  border-color: #03565A !important;
  box-shadow: none
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace10 {
  padding: 10px 0px;
}

.blankspace20 {
  padding: 20px 0px;
}

.blankspace30 {
  padding: 30px 0px;
}

.blankspace40 {
  padding: 40px 0px;
}

.blankspace50 {
  padding: 50px 0px;
}

.blankspace60 {
  padding: 60px 0px;
}

.blankspace70 {
  padding: 70px 0px;
}

.blankspace80 {
  padding: 80px 0px;
}

.blankspace90 {
  padding: 90px 0px;
}

.blankspace100 {
  padding: 100px 0px;
}


/* Tooltip Css Start */
.rtooltip {
  position: relative;
  display: inline-block;
}

.rtooltip .rtooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #f5f5f5;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  top: 27px;
  left: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  box-shadow: white 0px 8px 8px -4px;
}

.rtooltip:hover .rtooltiptext {
  visibility: visible;
}

/* Tooltip Css End */

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: lightblue 3px 4px 4px -3px !important;
}

.css-17ffvgn-MuiTypography-root {
  padding: 10px 15px !important;
}

.css-1eccwg7 {
  padding: 10px 15px !important;
}

.db-ir-tabs tr th {
  font-family: Inter !important;
}

.all-company thead th {
  font-family: Inter !important;
}

.open-radio-outer {
  width: 24px;
  height: 24px;
  background-color: #f3dbbe;
  border-radius: 50%;
}

.open-radio-inner {
  width: 11.74px;
  height: 11.74px;
  background-color: #fdc27b;
  border-radius: 50%;
  position: relative;
  top: 6px;
  left: 6px;
}

.viewIcon {
  width: 170px;
  height: 28px;
  display: flex;
  align-items: start;
  justify-content: start;
  background-color: #F8F9F9;
}

.view-options {
  width: 170px;
  height: 28px;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: -23px;

}
.icon-img{
width: 18px;
height: 18px;
margin-top: 5px;

}
.rename-icons{
  color:#32a899;
  background-color:#f5f5f5;
  border-radius:5px;
  font-size: 10px;
  margin:5px;
  padding:5px;
  justify-content: center;
  align-items: center;
}
.view-icons{
  color:#5d615f;
  background-color:#f5f5f5;
  border-radius:5px;
  font-size: 10px;
  margin:5px;
  padding:5px;
  justify-content: center;
  align-items: center;
}

.css-1dmzujt{
  box-shadow: lightblue 3px 4px 4px -3px !important;
}
.notstarted{
color: #828282;
background-color:#F8F8F8 ;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}
.inprogress{
color: #CC8000;
background-color: #FFF6E6;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}
.completed{
color: #068133;
background-color: #EDFFF3;
height: 30px !important;
border-radius: 5px;
padding: 5px;
}
.rixo-container{
    width: 90%;
    /* border: solid 1px #f00;  */
    margin: 0 auto;
    /* min-height: 800px     */
}
.rixo-containerfill{
    width: 100%;
    border: solid 1px #ccc;     
}
.rwp-btn{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid rgb(3, 86, 90);
    text-transform: none;   
    font-weight: 500;
    width: 250px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding: 10px 5px;
    text-transform: capitalize;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.search-btn{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid rgb(3, 86, 90);
    width: 360px;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    height: 50px;
    line-height: 0.5;
    border-radius: 0.25rem;
    padding: 5px 5px;
}
.rwp-title{
    font-size: 34px;
    font-weight: 600;
}
.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller{
    overflow: hidden;
    margin-bottom: 0px;
    /* background-color: #f4f4f4; */
}
/* bookclouser */
.search-btnv2{
    background-color: white;
    color: rgb(3, 86, 90);
    border: 2px solid #E5E8E8 ;
    width: 360px;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    height: 50px;
    line-height: 0.5;
    border-radius: 0.25rem;
    padding: 5px 5px;
}
.rounded-left-top {
    border-radius: 10px 0 0 0;
  }
.lease-register-hover:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.09) -1px 1px 4px
}

.category-assets {
    min-height: 350px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
}

.link-icon>.calndr {
    margin-right: -45px;
    height: 16px;
    width: 16px;
}

.link-iconn>.arrow-icon {
    margin-left: -45px;
    height: 30px;
    width: 30px;
}

.i-con>.drag-icon {
    width: 27px;
    height: 40px;
    color: #DFE2E7;

}

.preset-name {
    grid-gap: 5px;
    gap: 5px;
    margin-top: -30px;
}

.preset-name>.aprt-icon {
    width: 14px;
    height: 14px;
    color: #ABA9A9;

}

.preset-name>.company-text {
    line-height: 22px;
    color: #ABA9A9;

}

.preset-name>.cal-icon {
    width: 14px;
    height: 14px;
    color: #ABA9A9;

}

.dashed-border {
    border: 2px dashed #D6D6D6;
    border-radius: 12px;
}
/* 
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 7px ;

} */

.header__center {
    font-size: 2rem;
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
    color: #C3C8D3;
    padding: 5px;
}

.header__center::before,
.header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: currentColor;
}

.land-text>.land-card {
    height: 75px;
    background-color: #F8F8F8;
    border: 2px solid #ABA9A9;
    border-radius: 10px;
}

.category-assets>.enter-class {
    background-color: #D6D6D6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.financials-data {
    max-height: 80vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* new css */
.primaryy-border {
    border: 2px solid #03565A !important;
}

.header {
    background-color: #D1E0E0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.lease-table {
    background-color: #F8F8F8;
}

.lease-icon {
    color: #DFE2E7;
}

.custom-color {
    color: #ABA9A9;
}
.custom-textcolor {
    color: #717171;
}
.card>.import-card{
    border-radius: 12px;
    border: 2px solid #D6D6D6 ;
}
.addtext{
    margin-top: -34px;
}


/* @CSS for Side accordian */
.link-pl-35px{
    padding-left: 35px !important;
}

input[placeholder="MM/DD/YYYY"] {
    padding-left: 35px !important;
}

/* @tag-assets */
.tag-assets-options {
    min-height: 70vh;
    height: 70vh;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.tag-assets-option-hr{
    width: 40%;
    border-top: 2px solid #C3C8D3;
}
.border-radius-t-lf-10{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.tag-assets-option{
    box-shadow: -3px 3px 40px 0px #00000014;

    min-height: 55px;
}
.register-here{
    width: 224px;
}



/* @handle scroll */
.link-register-side-bar{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 75vh;
    overflow: auto;
}

.custom-container {
  width: 300px;
  height: 100%;
  background-color: white;
}

/* table,th,td,thead{
  border: none !important;
}
td,th{
  text-align:justify
} */

.lease_sidebar {
    height: 100vh;
    width: 300px;
    background-color: white;
}

.ne_sidebar {
    display: flex;
    justify-content: space-between;
    width: 85%;
}

.ne_sidebar>.ne_btn {
    background-color: #5CC8BC;
    border-radius: 10px;
    color: white;
    height: 60px;
    width: 50px;
    margin-top: 20px;
    text-transform: none;

}

.ne_sidebar>.ne_btn:hover {
    background-color: #5CC8BC;


}

.department_v1 {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top: 10px;
    padding: 13px;
    width: 85%;
    margin-left: 20px;

}

.leasehead{
    
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-left: 33px;
}
.leaseh5div{
    margin-left: 11px;
   
}
.leaseh5{
 font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #000000;
}
.leasep{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color:#7E7E7E;
}
.leasepie1{
    font-weight: 700;
    font-size: 18.07px;
    line-height: 28.11px;
    color: #000000
}
.accounthead{
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.ac-t-head{
    font-weight: 600;
    font-size: 12px;
    line-height: 14.52px;
}
.ac-t-content{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
    
}
.createstep{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #929292;
}
.createhead{
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
}

.create-para{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #939393;
}

.createsel{
    min-width: 426px;
    margin-left: 2px;
    margin-top: -11px;
    height: 61px;
    border-radius: 5px;
}
.create-inp-lab{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #696F79;
    margin-left: 3px;
   margin-top: 14px;
}

.create-inp{
    /* width:780px; */
    margin-top: -3px;
}
.createbtn{
    
     margin-top: 123px;
}
.fin-main-inp-w{
    max-width: 60rem;
}
.fin-lab-div{
    margin-top: 24px;
}
.fin-inp-div{
   
        min-width: 426px;
        border: 1px solid gray;
        border-radius: 6px;
        background-color: white;
        margin-top: 12px;
     
}
.fin-lab{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #696F79;
 margin-top: 12px;
}
.fin-drop{
    margin-top: 22px;
}
.fin-acco-dv{
 min-width: 910px;
 border-radius:5px !important;
}
.fin-acco-head{
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #000000 !important;
}
/* input[type=date]{
    color: transparent !important;
} */
/* input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
    display: none !important;
    align-items: flex-end !important;
    float:right !important
   
} */
/* input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
} */
.active-section {
    background-color: #F8F8F8;
    font-weight: 700;
    margin-bottom: 15px;

}

.inactive-section {
    background-color: white;
    border: 1px solid #D6D6D6 !important;
    margin-bottom: 15px;

}

.chekbox {
    height: 20px;
    width: 20px;
    margin-left: -3px;
}

.import-btn {
    width: 200px;
    height: 40px;
}

.select-btn>.select-colum {
    width: 180px;
    background-color: white;
    border: 2px solid #03565A;
}

.exit-btnv1>.btn-exit {
    width: 200px;
    height: 60;
    background-color: white;
}

.save-btn>.save-btnv2 {
    width: 200px;
    height: 50px;
}

.import-btnv1>.btnv1-imort {
    width: 150px;
    height: 50px;
    border-radius: 5px;
}

.borderless-table {
    border: none !important
}

.oneline-icon {
    width: 3px;
    height: 25px;
    background-color: #717171;
}

.task-btn>.btn-task {
    width: 200px;
    height: 45px;
}

.shadow-btn {
    height: 50px;
    border: 1px solid #DFE2E7;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .modal-btn>.modalsave-btn{
    width: 200px;
    height: 50px;
  }
  .modal-btn>.modalsave-btnv1{
    width: 300px;
    height: 50px;
  }
  /* modalsave-btn */
  .border-none{
    border: none;
  }
.section-style{
    max-height: 500px;
    overflow-y: auto;
    padding-right: 16px;
    box-sizing: border-box;
}
.new-textinput{
    background-color:#F2F3F4 ;
    border: 1px solid #8692A6;
    border-radius: 5px;
}
/*  */
.mr-top{
    margin-top: -12px;
}
.datepicker-color{
    /* background-color: white; */
    border: 2px solid #8692A6;
}
.section{
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    
}



  
  
.custom-table thead th:first-child {
    border-top-left-radius: 12px;
  }
  
  .custom-table thead th:last-child {
    border-top-right-radius: 12px;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
  }
  
  .custom-table {
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .custom-table .last-row td:first-child {
    border-bottom-left-radius: 12px;
  }
  
  .custom-table .last-row td:last-child {
    border-bottom-right-radius: 12px;
  }
  
  .general-box{
    height: 55px;
    background-color:#F8F8F8 ;
    border-radius: 15px;
   
  }
  .orange-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: orange;
   
  }
  .white-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: white;
  }
  .Primary-circle{
    height:30px ;
    width: 30px;
    border-radius: 15px;
    background-color: #03565A;
  }
 .no-border {
  border: none;
}

.text-left {
  text-align: left;
}

.last-cell {
  border-bottom-right-radius: 10px;
}

.table-custom thead{
    height:90px;
    background: #F8FAFB;

}

.table-custom th{
    border: 1.5px solid #D9D9D9; 
    background: #F8FAFB;    
    border-top: none;
    border-bottom: none;
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}


.table-custom th:first-child{
    border-left: none;
    text-align: left;
    width: 50%;
}
.table-custom th:nth-child(2){
    text-align: left;
    width: 10%;
}
.table-custom th:nth-child(3){
    text-align: left;
    width: 20%;
}
.table-custom th:last-child{
    text-align: left;
    width: 20%;
    border-right: none;
}

.table-custom td{
    border: 1.5px solid #dddddd; 
    height: 35px;
    border-top: none;
    border-bottom: none;   
    color: black;
    background-color: white;
}
.table-custom td:first-child{
    border-left: none;
    text-align: left;
    padding-left: 1rem;    
    width: 50%;
}
.table-custom td:nth-child(2){    
    text-align: left;
    padding-left: 1rem;
    width: 10%;
}
.table-custom td:nth-child(3){    
    text-align: left;
    padding-left: 1rem;
    width: 20%;
}
.table-custom td:last-child{
    text-align: left;
    /* padding-left: 1rem; */
    width: 20%;
    border-right: none;
}

.adjustment-p{
    background-color: #03565A;
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
}

/* .tooltip-hoverIcon{
    cursor: pointer;
    color: #b3b3b3;
    filter: drop-shadow(0px 4px 3px #dddddd);
}
.tooltip-hoverIcon:hover{
    color: #999;
    transform: scale(1.1) translateZ(0px);
    transition: 0.35s ease-in-out;
    filter: drop-shadow(0px -2px 5px #dddddd);
} */

.adjustment-shadow {
    box-shadow: 0px 1px 4px 1px #b3d2d4;
}

.adjustment-border {
    border: 1px solid #03565a;
}
.adjustment-border:focus{
    box-shadow: #b3d2d4 0px 2px 5px -1px, #03565a 0px 1px 3px -1px !important;
}
.preset-section {
    margin-top: 1.5rem;
    border: 2px dashed #D6D6D6;
    border-radius: 10px;
    height: 18rem;
    cursor: pointer;
    background-color: #F8F8F8;
}

.preset-drop {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drop-folder {
    width: 45px !important;
    height: 35px !important; 
    color: #573E20;
}

.drop-border {
    border: 2px dashed #03565A;
    border-radius: 10px;
    background-color: #EBF2F3;
}

.include-value {
    font-weight: 400;
    font-size: 1rem;
}

.include-value-d {
    font-weight: 200;
    font-size: 1rem;
}

/* MUI select */
.MuiMenu-list {
    display: flex;
    flex-direction: column;
}

/* new css */
.border-color {
    border: 2px solid #03565A;
}
.custom-card{
    border: 2px solid #D6D6D6;
}
.preset-map-div {
    height: 184px;
    overflow-y: auto;
}

.scroll-y-preset {
    height: 33rem;
    overflow-y: scroll;
}
.Main_div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    gap: 50px;
}

.Main_div>.Main_Card {
    width: 406px;
    height: 350px;
    background-color: #D1E0E0;
    border-radius: 12px;
}

.Img_Style {
    width: 262px;
    height: 254px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.lock_btn {
    position: relative
}

.lock-profile {
    height: 100%;
    Position: absolute;
    top: 0;
    padding-top: 80%;
    width: 100%;
    padding: 114px;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
}

.Scnd_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    width: 408px;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: none !important;
}

.px_25 {
    padding: 10px 25px;
}

.Main_card {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 100%;
    width: 100%;
    padding-top: 23px;

}

.css-1cuxlhl {
    display: none !important;
}

.p-table {
    font-weight: 800;
    font-size: 16px;
    color: #03565A;
}

.p-secondtable {
    color: gray;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;

}

.p-thirdtable {
    color: black;
    text-align: left;
    margin-left: 12px;
    margin-top: 9px;

}

.css-10d9dml-MuiTabs-indicator {
    left: 0 !important;
    width: 5px !important;
    background-color: inherit !important;
}

.form {
    margin-top: 10px;
    width: 200px;

    position: relative;
}

.form_v1 {
    width: 150px;

    position: relative;

}

.form .fa-search {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #9ca3af;
}

.left-pan {
    padding-left: 7px;
}

.left-pan i {
    padding-left: 10px;
}

.form-input {

    height: 55px;
    text-indent: 33px;
    border-radius: 10px;
}

.form-input:focus {
    box-shadow: none;
    border: none;
}

.form-control {
    /* background-color: white; */
}

.search_box{
    position: absolute;
    top: 15px;
    left: 10px; 
}
.search_bttn {
    position: absolute;
    top: 20px;
    margin-left: 10px;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin-top: 4px;
    border-left: 8px solid #03565A;
}

.table_culmn {
    text-align: center;
    /* line-height: 148px; */
    min-width: 6rem;
    background-color: #F2F2F2;
}

.intro {
    font-weight: 700;
    font-weight: 25px;
    font-style: normal;
    line-height: normal;
    color:black;
}

.intro_title {
    font-size: 16px;
    font-weight: 500;
    color: black;
}
.scope{
    font-weight: 700;
    font-size: 22px;
    color: #03565A;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    border-radius: 15px;
}

.text-700{
    font-weight: 700 !important;
}
.content-sidebar{
    background-color: #CDDDDE;
    max-height: 40rem;
    position: fixed !important;
    top: 4.7rem;
    overflow-y: auto;
}
.content-children{
    position: relative;
    width: 80%;
    left: 17%;
    top: 5rem;
}
.content-header{
    width: 100%;
    height: 75px;
    background-color: #03565A;
    position: fixed;
    z-index: 1;
}

.lib-data-section{
    color: black;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none !important;
    -webkit-text-decoration-color: transparent !important;
            text-decoration-color: transparent !important;
}
.custom-table thead th:first-child {
  border-top-left-radius: 12px;
}

.custom-table thead th:last-child {
  border-top-right-radius: 12px;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table .last-row td:first-child {
  border-bottom-left-radius: 12px;
}

.custom-table .last-row td:last-child {
  border-bottom-right-radius: 12px;
}

.header-text {
  color: #717171;
}
.table-row-span td {
  /* Ensure the first cell spans multiple rows */
  vertical-align: top; /* Adjust as needed */
}

.table-row-span td:nth-child(1) {
  /* Adjust the number based on which column you want to visually span */
  position: relative;
  height: auto; /* Adjust as needed */
}

.table-row-span td:nth-child(1)::before {
  /* Adjust the positioning and styling to create a visual row span effect */
  content: '';
  position: absolute;
  top: 0;
  left: 50%; /* Adjust to center the line */
  width: 2px; /* Adjust line thickness */
  height: 100%;
  background-color: #ccc; /* Adjust line color */
}


.checked .custom-checkbox:before {
    background-color: green;
}

.finalise-textcolor {
    background-color: #F8F8F8;
}

.custom-redcolor {
    color: red;
}

/* styles.css */
.coustom-blucolor {
    color: #4061FF;
}

.coustom-color {
    background-color:#D6D6D6 ;
}
.coustom-textalign{
    text-align: start;
}
.card-radious{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}
.save-button {
    width: 150px;
    height: 40px; 
}
.import-button {
    text-transform: none !important ;
    width: 265px;
    height: 40px;
    border: 2px solid #03565A !important ;
}
.preset-button {
    border-color: 2px solid #03565A;
    width: 200px;
}

.handle-scroll {
    max-height: 300px;
    overflow-y: auto;
}
.pybox{
    max-width: 100%;
    padding-left: 100px;
}
